import React from "react";
import { View } from "react-native";
import { Button, Subheading, Text, useTheme } from "react-native-paper";

import { ScreenHeader } from "../Auth/ScreenHeader";
import { useBackup } from "../hooks/useBackup";

const QueryBackup = ({ navigation }: any) => {
  console.log(" -> QueryBackup");
  const theme = useTheme();
  const {
    importSearches,
    importDesktopSearches,
    exportSearches,
    backupStatus,
    loading,
  } = useBackup();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        alignItems: "stretch",
        flexGrow: 1,
      }}
    >
      <ScreenHeader
        action="back"
        icon="close"
        title="Import/Export eBay Searches"
        navigation={navigation}
      />

      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "center" }}>
          {/* Text & Buttons */}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              margin: 30,
            }}
          >
            {/* Text */}
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text>Export your searches to CSV file.</Text>
              </View>

              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text>Import searches from CSV.</Text>
                <Text>
                  To see required file format - export some searches first.
                </Text>
              </View>

              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text>Import searches from uBuyFirst Desktop.</Text>
              </View>
            </View>

            {/* Buttons */}
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <View>
                <Button
                  style={{ margin: 30 }}
                  mode="outlined"
                  onPress={exportSearches}
                >
                  Export
                </Button>
              </View>
              <View>
                <Button
                  style={{ margin: 30 }}
                  mode="outlined"
                  onPress={importSearches}
                >
                  Import
                </Button>
              </View>
              <View>
                <Button
                  style={{ margin: 30 }}
                  mode="outlined"
                  onPress={importDesktopSearches}
                >
                  Import Desktop
                </Button>
              </View>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              padding: 30,
            }}
          >
            <Subheading>
              {loading ? "Database processing..." : backupStatus}
            </Subheading>
          </View>
        </View>
      </View>
    </View>
  );
};

export { QueryBackup };
