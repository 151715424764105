import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";
import { useDimensions } from "./useDimensions";

export const useCardsWidth = () => {
  const { window } = useDimensions();
  const dispatch = useDispatch();
  const setCardWidth = (cardWidth: any) => {
    dispatch({ type: "SET_CARD_SIZE", payload: cardWidth });
  };

  const cardWidth = useSelector<IRootReducer, number>(
    (state) => state.appState.cardSize,
  );

  const calcColumnNumber = (windowWidth: number, cardWidth: number) => {
    let num = Math.floor(windowWidth / cardWidth);
    if (!num) num = 1;
    return num;
  };

  const [columnNumber, setColumnNumber] = useState(
    calcColumnNumber(window.width, cardWidth),
  );

  useEffect(() => {
    setColumnNumber(calcColumnNumber(window.width, cardWidth));
  }, [cardWidth, window]);

  return { cardWidth, setCardWidth, columnNumber, window };
};
