import React, { useRef } from "react";
import { Image, Modal, View } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";

import { ChangeImageButton } from "./ChangeImageButton";
import { ImageCounter } from "./ImageCounter";
import { ThumbnailRow } from "./ThumbnailRow";

export const FullScreenGallery = ({
  isOpen,
  imageHeight,
  images,
  currentIndex,
  onClose,
  setIndex,
}: {
  isOpen: any;
  images: any;
  imageHeight: number;
  currentIndex: number;
  setIndex: (index: number) => void;
  onClose: () => void;
}) => {
  const imageViewerRef = useRef<ImageViewer | null>(null);

  const clickNext = () => {
    const nextIndex =
      currentIndex < images.length - 1 ? currentIndex + 1 : currentIndex;
    setIndex(nextIndex);
  };

  const clickPrev = () => {
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    setIndex(prevIndex);
  };

  const trimShiftOnZoom = (num: number) => {
    num = num / 4;
    if (num > 0) return Math.min(num, 250);
    if (num < 0) return Math.max(-250, num);
    return 0;
  };

  const onWheel = (e: {
    deltaY: number;
    currentTarget: { getBoundingClientRect: () => any };
    pageX: number;
    pageY: number;
  }) => {
    const zoomDelta = e.deltaY > 0 ? 0.8 : 1.2;
    //@ts-ignore
    const imgRef = imageViewerRef.current?.imageRefs[currentIndex];
    imgRef.height = imageHeight;
    if (imgRef) {
      const pic = e.currentTarget.getBoundingClientRect();
      const xShift = e.pageX - pic.left - pic.width / 2;
      const yShift = e.pageY - pic.top - pic.height / 2;
      imgRef.centerOn({
        x: -trimShiftOnZoom(xShift),
        y: -trimShiftOnZoom(yShift),
        scale: imgRef.scale * zoomDelta,
      });
    }
  };

  return (
    <Modal onDismiss={onClose} visible={isOpen} onRequestClose={onClose}>
      <View style={{ flex: 1, backgroundColor: "black" }}>
        <ImageViewer
          ref={imageViewerRef}
          style={{ marginVertical: 10 }}
          doubleClickInterval={0}
          useNativeDriver={true}
          enablePreload={true}
          imageUrls={images ? images : []}
          onChange={(index: any) => {
            setIndex(index ?? 0);
          }}
          onClick={onClose}
          index={currentIndex}
          renderArrowRight={() => (
            <ChangeImageButton
              visible={currentIndex < images.length - 1}
              action={clickNext}
              icon="chevron-right"
            />
          )}
          renderArrowLeft={() => (
            <ChangeImageButton
              visible={currentIndex > 0}
              action={clickPrev}
              icon="chevron-left"
            />
          )}
          renderIndicator={(currentIndex, allSize) => (
            <ImageCounter currentIndex={currentIndex} allSize={allSize} />
          )}
          renderImage={(imgProps) => {
            imageViewerRef.current?.resetImageByIndex(currentIndex);

            return (
              <Image
                source={imgProps.source}
                style={{ flex: 1 }}
                //@ts-ignore
                onWheel={onWheel}
              />
            );
          }}
        />
        <ThumbnailRow
          images={images}
          currentIndex={currentIndex}
          setIndex={setIndex}
        />
      </View>
    </Modal>
  );
};
