import React from "react";
import { StyleSheet, View } from "react-native";
import { IconButton, Subheading, TouchableRipple } from "react-native-paper";

export const BackgroundNotificationsComponent = ({ navigation }: any) => {
  return (
    <TouchableRipple
      onPress={() => {
        navigation.navigate("BatteryScreen");
      }}
    >
      <View style={styles.preference}>
        <Subheading>Notifications in background</Subheading>

        <IconButton
          style={{ marginVertical: -5 }}
          icon="chevron-right"
          iconColor={"grey"}
        ></IconButton>
      </View>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 12,
    alignItems: "center",
  },
});
