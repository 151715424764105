import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { QueryHookOptions, useLazyQuery } from "@apollo/client";

import { useSound } from "../hooks/useSound";
import { INode } from "../interfaces/Node";
import { IRootReducer } from "../reducers/allReducers";
import { itemsQuery, itemsSubscription } from "./Queries";

export const useGetItems = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const { debouncedSound } = useSound();
  const resultsNumber = parseInt(
    useSelector<IRootReducer, string>((state) => state.appState.resultsNumber)
  );
  const uid = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid
  );

  const itemQueryVars: QueryHookOptions = {
    variables: {
      pageIndex: 1,
      uid,
      first: resultsNumber,
    },
    fetchPolicy: "cache-and-network"
  };
  const [
    loadItems,
    { data, subscribeToMore, loading, variables },
  ] = useLazyQuery(itemsQuery, itemQueryVars);

  useEffect(() => {
    if (variables && setPageIndex != variables.pageIndex) {
      setPageIndex(variables.pageIndex);
    }
  }, [variables]);

  useEffect(() => {
    if (!subscribeToMore) {
      return;
    }

    const unsubscribe = subscribeToMore({
      document: itemsSubscription,
      variables: { uid },
      updateQuery: (prev: { userItems2: { edges: any; }; }, { subscriptionData }) => {
        // 1. Early return conditions
        if (
          variables?.pageIndex !== 1 ||
          !subscriptionData.data ||
          !prev ||
          !subscriptionData.data.newItem
        ) {
          debouncedSound.current();
          return prev;
        }

        const oldEdges = prev.userItems2?.edges || [];
        const newItem = subscriptionData.data.newItem;

        // 2. Check if the new item already exists in the cached data
        const itemExists = oldEdges.some((item: { node: { id: any; }; }) => item.node.id === newItem.id);

        if (itemExists) {
          return prev;  // Return the existing cache if the item is already present
        }

        // 3. Trim the old edges if they exceed the allowed limit
        const trimmedEdges = oldEdges.slice(0, resultsNumber - 1);

        // 4. Create a new array of edges, with the new item at the beginning
        const newEdges = [
          {
            __typename: "QueryUserItems2ConnectionEdge",
            node: { ...newItem }
          },
          ...trimmedEdges
        ];

        // 5. Create the updated cache data
        const updatedData = {
          ...prev,
          userItems2: {
            ...prev.userItems2,
            edges: newEdges,
          },
        };

        debouncedSound.current();

        return updatedData;
      },
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToMore, resultsNumber, debouncedSound, pageIndex]);

  return { data, loading, loadItems };
};
