import React from "react";
import { Appbar } from "react-native-paper";

import { useDeleteItems } from "../Graphql/useDeleteItems";

export const DeleteAllResultsButton = () => {
  const { deleteAllItems } = useDeleteItems();
  return (
    <Appbar.Action
      icon={"delete-outline"}
      onPress={deleteAllItems}
      size={30}
      color={"white"}
    />
  );
};
