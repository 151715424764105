import { useMemo } from "react";

interface UsePaginationProps {
  /** Total number of rows */
  count: number;
  /** The current page */
  page: number;
  /** How many rows per page should be visible */
  rowsPerPage: number;
  /** What are the provided options for rowsPerPage */
  rowsPerPageOptions: number[];
}
export function usePagination({
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}: UsePaginationProps) {
  const pageCount = useMemo(() => {
    return Math.ceil(count / rowsPerPage);
  }, [count, rowsPerPage]);

  const pages = useMemo(() => {
    const arr: number[] = [];
    if (pageCount === 0) return arr;

    if (page > 5) {
      arr.push(page - 5);
    }

    arr.push(page);
    if (page + 5 < pageCount && page != 1) {
      arr.push(page + 5);
    }
    return arr;
  }, [page, pageCount]);

  const showFirst = useMemo(() => {
    return page > 3;
  }, [page]);

  const showNext = useMemo(() => {
    return pageCount - page > 0;
  }, [page, pageCount]);

  const showLast = useMemo(() => {
    return pageCount - page > 2;
  }, [page, pageCount]);

  const showPages = useMemo(() => {
    return pages.length !== 1;
  }, [pages.length]);

  const showPagination = useMemo(() => {
    return count >= Math.min(...rowsPerPageOptions);
  }, [count, rowsPerPageOptions]);

  const showPrevious = useMemo(() => {
    return page > 1;
  }, [page]);

  return {
    pages,
    pageCount,
    showFirst,
    showNext,
    showLast,
    showPages,
    showPagination,
    showPrevious,
  };
}
