import React, { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { Text, TextInput, useTheme } from "react-native-paper";

import { ConditionDict } from "../../Tools/ConditionDict";
import { DropdownIcon } from "./DropdownIcon";

const DropdownTextInput = (props: {
  selectedItemsRef: React.MutableRefObject<any>;
  text: string;
  label: string;
  onPress: (arg0: any) => void;
}) => {
  const theme = useTheme();

  const [checkedDescription, setCheckedDescription] = useState(props.text);
  useEffect(() => {
    const checkedItems = Object.entries(ConditionDict)
      .filter((c: any) => props.selectedItemsRef.current.includes(c[0]))
      .map((c) => c[1]);

    if (checkedItems.length == 0 || checkedItems.length == 11) {
      setCheckedDescription("Any");
    } else {
      setCheckedDescription(checkedItems.join(", "));
    }
  }, [props.selectedItemsRef.current]);

  return (
    <TextInput
      style={{
        flexGrow: 1,
        margin: 6,
        backgroundColor: theme.colors.surface,
      }}
      label={props.label}
      value={" "}
      mode="outlined"
      render={() => (
        <Pressable
          onPress={props.onPress}
          style={{
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              marginLeft: 10,
              marginRight: 20,
              marginVertical: 5,
            }}
          >
            {checkedDescription}
          </Text>
          <DropdownIcon />
        </Pressable>
      )}
    />
  );
};

export { DropdownTextInput };
