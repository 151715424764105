import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import {
  BaseDataProvider,
  DataProvider,
  LayoutProvider,
  RecyclerListView,
} from "recyclerlistview";

import { useCardsWidth } from "../hooks/useCardsWidth";
import { useDimensions } from "../hooks/useDimensions";
import { INode } from "../interfaces/Node";
import { PaginationRecycler } from "../Pagination/PaginationRecycler";
import { IRootReducer } from "../reducers/allReducers";
import { ResultItem } from "./ResultItem";

const RecyclerList1 = (props: {
  data: any[];
  loadItems: any;
  totalCount: number;
}) => {
  const { window } = useDimensions();
  const [nodeList, setNodeList] = useState<any[]>([]);
  const [dataProv, setDataProv] = useState<BaseDataProvider | undefined>();

  useEffect(() => {
    if (nodeList && nodeList.length > 0) {
      const newDataProv = new DataProvider((r1, r2) => r1 !== r2).cloneWithRows(
        nodeList,
      );
      setDataProv(newDataProv);
    }
  }, [nodeList]);

  useEffect(() => {
    //@ts-ignore
    const list: any[] = props.data?.userItems2.edges
      ?.filter((i: any) => !i.node.deleted)
      .map((i: any) => i.node);
    const isNewData = !ArraysEqual(
      nodeList?.map((node: INode) => node.id),
      list?.map((node: INode) => node.id),
    );
    if (isNewData) {
      setNodeList(list);
    } else {
    }
  }, [props.data]);

  const rowRenderer = useCallback((_type: any, data: INode) => {
    return <ResultItem node={data} />;
  }, []);

  const { columnNumber: colNum } = useCardsWidth();
  const viewType = useSelector<IRootReducer, string>(
    (state) => state.appState.viewType,
  );

  const layoutProvider = useMemo(() => {
    return new LayoutProvider(
      () => viewType,
      (type, dim) => {
        dim.width = Math.round(window.width / colNum) - 1;
        dim.height = calcCardHeight(type.toString(), colNum, window.width);
      },
    );
  }, [colNum, viewType, window]);

  const listView = useRef<any>();
  const [pageIndex, setPageIndex] = useState(1);
  const scrollToTop = () => {
    listView.current?.scrollToTop(false);
  };

  return dataProv && nodeList && nodeList.length > 0 ? (
    <View style={{ flex: 1 }}>
      <RecyclerListView
        ref={listView}
        renderFooter={() => (
          <PaginationRecycler
            totalCount={props.totalCount}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            scrollToTop={scrollToTop}
            loadItems={props.loadItems}
            canChangeSize
          />
        )}
        layoutProvider={layoutProvider}
        dataProvider={dataProv}
        rowRenderer={rowRenderer}
        canChangeSize={true}
        suppressBoundedSizeException={true}
      />
    </View>
  ) : null;
};

const calcCardHeight = (
  type: string,
  columnNumber: number,
  windowWidth: number,
) => {
  const rowWidth = Math.floor(windowWidth / columnNumber);
  const rowHeight = 130;
  let height = rowHeight;
  switch (type) {
    case "column":
      if (rowWidth >= 270) height = 365;
      else if (rowWidth >= 190 && rowWidth <= 270) height = 390;
      else if (rowWidth <= 190) height = 440;

      break;
    case "row":
      if (columnNumber == 1) {
        if (rowWidth >= 550) height = rowHeight;

        if (rowWidth <= 250) height = 300;
        if (rowWidth >= 250 && rowWidth <= 550) height = 580 - rowWidth;
      } else {
        if (rowWidth < 550) {
          height = rowHeight + (550 - rowWidth) / 3;
        } else {
          height = rowHeight;
        }
      }
      break;
  }

  return height;
};

const ArraysEqual = (arrA: any[], arrB: any[]) => {
  if (arrA?.length !== arrB?.length) return false;
  var cA = arrA?.join(",");
  var cB = arrB?.join(",");
  return cA === cB;
};

const RecyclerList = React.memo(RecyclerList1);
export { RecyclerList };
