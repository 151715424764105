import React, { useRef } from "react";
import { View } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";

import { ChangeImageButton } from "./ChangeImageButton";
import { ImageCounter } from "./ImageCounter";

export const MainImage = ({
  images,
  index,
  setIndex,
  onClick,
  imageHeight,
}: any) => {
  const imageViewerRef = useRef<ImageViewer | null>(null);

  const clickNext = () => {
    const nextIndex = index < images.length - 1 ? index + 1 : index;
    setIndex(nextIndex);
  };
  const clickPrev = () => {
    const prevIndex = index > 0 ? index - 1 : index;
    setIndex(prevIndex);
  };

  return (
    <View
      style={{
        height: imageHeight,
        backgroundColor: "black",
      }}
    >
      <ImageViewer
        ref={imageViewerRef}
        style={{ marginVertical: 5 }}
        doubleClickInterval={0}
        useNativeDriver={true}
        enablePreload={true}
        imageUrls={images ? images : []}
        backgroundColor={"transparent"}
        onChange={(index) => {
          setIndex(index ?? 0);
        }}
        onClick={onClick}
        index={index}
        renderArrowRight={() => (
          <ChangeImageButton
            visible={index < images.length - 1}
            action={clickNext}
            icon="chevron-right"
          />
        )}
        renderArrowLeft={() => (
          <ChangeImageButton
            visible={index > 0}
            action={clickPrev}
            icon="chevron-left"
          />
        )}
        renderIndicator={(currentIndex, allSize) => (
          <ImageCounter currentIndex={currentIndex} allSize={allSize} />
        )}
      />
    </View>
  );
};
