import React, { useContext } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import {
  Drawer,
  IconButton,
  Switch,
  Text,
  TouchableRipple,
  withTheme,
} from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";

import { DrawerContentScrollView } from "@react-navigation/drawer";

import { ThemeToggleContext } from "../contexts/ThemeContext";
import { Platform } from "../Platform";
import { IRootReducer } from "../reducers/allReducers";
import { CardWidthSlider } from "../SearchResults/CardWidthSlider";
import { openUrl } from "../Tools/openUrl";

const DrawerItemsData = [
  { label: "Home", icon: "home", key: 0 },
  { label: "Searches", icon: "book-multiple", key: 1 },
  { label: "Settings", icon: "cog", key: 2 },
];

const DrawerItems = ({ navigation }: any) => {
  var routeName = "Home";
  var routeState = navigation.state.routes[0].state;
  if (routeState) routeName = routeState.routes[routeState.index].name;
  //@ts-ignore
  const { theme, toggleTheme } = useContext(ThemeToggleContext);
  const dispatch = useDispatch();

  const soundEnabled = useSelector<IRootReducer, boolean>(
    (state) => state.appState.soundEnabled,
  );

  const viewType = useSelector<IRootReducer, string>(
    (state) => state.appState.viewType,
  );

  return (
    <SafeAreaView
      style={[
        styles.drawerContent,
        {
          backgroundColor: theme?.dark
            ? theme?.colors?.surface
            : theme?.colors?.primary,
        },
      ]}
      edges={["left"]}
    >
      <SafeAreaView
        edges={["left", "top"]}
        style={{
          elevation: 2,
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          backgroundColor: theme.dark
            ? theme.colors.surface
            : theme.colors.primary,
        }}
      >
        <Text key="Back button"> </Text>
        <IconButton
          style={{ justifyContent: "center" }}
          icon="chevron-left"
          size={25}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </SafeAreaView>

      <View
        style={{
          flex: 6,
          paddingTop: 10,
          backgroundColor: theme.colors.background,
        }}
      >
        <DrawerContentScrollView>
          <Drawer.Section>
            {DrawerItemsData.map((drawerItems) => (
              <Drawer.Item
                {...drawerItems}
                key={drawerItems.key}
                active={routeName === drawerItems.label}
                onPress={() => {
                  if (routeName === drawerItems.label) {
                    return;
                  }
                  navigation.navigation.navigate(drawerItems.label);
                }}
              />
            ))}
          </Drawer.Section>

          <Drawer.Section>
            <TouchableRipple onPress={toggleTheme}>
              <View style={styles.preference}>
                <Text>Dark Theme</Text>
                <View pointerEvents="none">
                  <Switch value={theme.dark} />
                </View>
              </View>
            </TouchableRipple>

            {/*Sound enabled*/}
            <TouchableRipple
              onPress={() => {
                dispatch({ type: "SET_SOUND_ENABLED", payload: !soundEnabled });
              }}
            >
              <View style={styles.preference}>
                <Text>Sound</Text>
                <View pointerEvents="none">
                  <Switch value={soundEnabled} />
                </View>
              </View>
            </TouchableRipple>

            <TouchableRipple
              onPress={() => {
                dispatch({
                  type: "SET_VIEW_TYPE",
                  payload: viewType === "column" ? "row" : "column",
                });
              }}
            >
              <View style={styles.preference}>
                <Text>{viewType === "column" ? "Tile view" : "Row view"}</Text>
                <View pointerEvents="none">
                  <IconButton
                    size={35}
                    icon={
                      viewType === "column"
                        ? "view-module"
                        : "view-stream-outline"
                    }
                    style={{
                      marginVertical: -15,
                      marginRight: -5,
                    }}
                  />
                </View>
              </View>
            </TouchableRipple>

            {Platform.OS != "web" && (
              <View style={styles.preference}>
                <Text>Card size</Text>
                <CardWidthSlider />
              </View>
            )}
          </Drawer.Section>

          <Drawer.Section>
            {Platform.OS === "web" && (
              <Drawer.Item
                icon="google-play"
                label="Android app"
                key={"android-app"}
                onPress={() => {
                  openUrl(
                    "https://play.google.com/store/apps/details?id=com.ubuyfirst",
                  );
                }}
              />
            )}
            <Drawer.Item
              icon="face-agent"
              label="Support chat"
              key={"support"}
              onPress={() => {
                openUrl(
                  "https://tawk.to/chat/5edbe65f9e5f6944229008b2/default",
                );
              }}
            />
            <Drawer.Item
              icon="information-outline"
              label="About"
              key="about"
              onPress={() => {
                navigation.navigation.navigate("About");
              }}
            />
          </Drawer.Section>
        </DrawerContentScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  drawerContent: { flex: 1 },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});

export default withTheme(DrawerItems);
