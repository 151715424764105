import React, { useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Surface, useTheme } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import { PermissionBanner } from "../Auth/PermissionBanner";
import { ScreenHeader } from "../Auth/ScreenHeader";
import { SignUpForm } from "../Auth/SignInForm";
import { SignUpBanner } from "../Auth/SignUpBanner";
import { VersionCheck } from "../components/VersionCheck";
import { IUser } from "../interfaces/AppState";
import { IRootReducer } from "../reducers/allReducers";
import { ResultListComponent } from "./ResultList";

const HomeScreen = (props: any) => {
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [notifyPermissionVisible, setNotifyPermissionVisible] = useState(false);
  const toggleNotifyPermissionVisible = () => {
    setNotifyPermissionVisible(!notifyPermissionVisible);
  };

  const theme = useTheme();

  const user = useSelector<IRootReducer, IUser>((state) => state.appState.user);

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["right", "bottom", "left"]}>
      <View style={{ flex: 1, backgroundColor: theme.colors.background }}>
        <ScreenHeader
          action={"drawer"}
          navigation={props.navigation}
          toggleNotifyPermissionVisible={toggleNotifyPermissionVisible}
        />
        <PermissionBanner notifyPermissionVisible={notifyPermissionVisible} />

        <SignUpBanner
          navigation={props.navigation}
          signUpVisible={signUpVisible}
          setSignUpVisible={setSignUpVisible}
        />

        {signUpVisible && user.isAnonymous ? (
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <Surface style={{ elevation: 9, width: 300 }}>
              <SignUpForm />
            </Surface>
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {Platform.OS === "web" && <VersionCheck />}
            <ResultListComponent />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default HomeScreen;

export const styles = StyleSheet.create({ container: { flex: 1 } });
