export const FS = {
  timestamp: "t",
  terms: "te",
  users: "u",
  online: "online",
  device: "d",
  platform: "p",
  version: "v",
  pushToken: "pt",
  searchStarted: "started",
  notificationsEnabled: "n",
};
