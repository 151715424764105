import React, { useState } from "react";
import { AnyObject, Field, Form, FormSpy } from "react-final-form";
import { ScrollView, StyleSheet, View } from "react-native";
import {
  Divider,
  HelperText,
  List,
  Surface,
  useTheme,
} from "react-native-paper";

import { ScreenHeader } from "../Auth/ScreenHeader";
import { CheckedDropdown } from "../components/CheckedDropdown/CheckedDropdown";
import { FABCustom } from "../components/DropDowns/FABCustom";
import { PickerCustom } from "../components/DropDowns/PickerCustom";
import { CountryCodeTypeFrom, CountryCodeTypeTo } from "../Geo/CountryCodes";
import { eBaySites } from "../Geo/eBaySite";
import { useTerms } from "../Graphql/useTerms";
import { Query } from "../interfaces/Query";
import { Platform } from "../Platform";
import { queryValidator } from "../Tools/validation";
import { CategoryEdit, CategoryEditIOS } from "./CategoryEdit";
import { QueryNumInput, QueryTextInput } from "./QueryTextInput";

const SearchQueryEdit = (props: {
  route: { params: { query: Query } };
  navigation: void[];
}) => {
  var handleSubmitOnFAB: (
    event?: React.SyntheticEvent<HTMLFormElement>,
  ) => Promise<AnyObject | undefined> | undefined;

  const [FABDisabled, setFABDisabled] = useState(false);
  const { createTerm, updateTerm } = useTerms();
  const theme = useTheme();
  //@ts-ignore
  const queryParam: Query = props.route.params.query;

  const FieldCondition = ({ when, isNot, children }: any) => (
    <Field
      name={when}
      subscription={{
        value: true,
      }}
    >
      {({ input: { value } }) => (value !== isNot ? children : null)}
    </Field>
  );

  const onSubmit = (query: Query) => {
    //query.priceMin = parseInt(query.priceMin.toString());
    //query.priceMax = parseInt(query.priceMax.toString());
    try {
      if (query.id) {
        updateTerm(query);
      } else {
        createTerm(query);
      }
    } catch (e) {
      console.log("Saving keyword: ", e);
    }
    props.navigation.pop();
  };

  return (
    <View style={{ flex: 1 }}>
      <ScreenHeader
        action="close"
        icon="close"
        title="Edit Search"
        navigation={props.navigation}
      />
      <FABCustom
        disabled={FABDisabled}
        label={"Save"}
        icon={"check"}
        onPress={() => handleSubmitOnFAB()}
      />
      <ScrollView
        keyboardShouldPersistTaps={
          Platform.OS === "ios" || Platform.OS === "android"
            ? "always"
            : "never"
        }
        style={{
          backgroundColor: theme.colors.background,
        }}
      >
        <Form
          initialValues={{ ...queryParam }}
          onSubmit={onSubmit}
          subscription={{ submitting: true, pristine: true }}
          validate={queryValidator}
          validateOnBlur={false}
          render={({ handleSubmit, submitting }) => {
            handleSubmitOnFAB = handleSubmit;
            setFABDisabled(submitting);
            return (
              <View>
                <Surface style={styles.surface}>
                  <FormSpy>
                    {({
                      hasSubmitErrors,
                      submitError,
                      hasValidationErrors,
                      errors,
                    }) =>
                      (hasSubmitErrors || hasValidationErrors) && (
                        <HelperText
                          type="error"
                          visible={
                            (hasSubmitErrors && submitError) ||
                            hasValidationErrors
                          }
                        >
                          {submitError}
                          {
                            //@ts-ignore
                            errors["undefined"]
                          }
                        </HelperText>
                      )
                    }
                  </FormSpy>

                  <QueryTextInput
                    name="alias"
                    label={"Alias"}
                    style={[styles.formInput, {}]}
                    theme={theme}
                  />
                  <Divider />
                  <QueryTextInput
                    name="keyword"
                    label="Keyword"
                    style={[styles.formInput, {}]}
                  />

                  <Divider />
                  {Platform.OS !== "ios" ? (
                    <CategoryEdit />
                  ) : (
                    <CategoryEditIOS />
                  )}

                  <Divider />

                  <View style={{ flexDirection: "row" }}>
                    <QueryNumInput
                      name="priceMin"
                      style={[styles.formInput]}
                      keyboardType="numeric"
                      label="Price Minimum"
                    />
                    <QueryNumInput
                      name="priceMax"
                      style={[styles.formInput]}
                      keyboardType="numeric"
                      label="Price Maximum"
                    />
                  </View>
                </Surface>

                <List.Accordion title="Advanced options">
                  {/* Dropdown menu with multiple checked */}
                  <Surface style={styles.surface}>
                    {/* <Field name="searchInDescription">
                      {({ input }) => {
                        return (
                          <TouchableRipple
                            onPress={() => input.onChange(!input.value)}
                          >
                            <Surface
                              style={{
                                elevation: 1,
                                margin: 6,
                              }}
                            >
                              <View
                                pointerEvents="none"
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  height: 64,
                                  paddingHorizontal: 12,
                                }}
                              >
                                <Subheading>Search in description</Subheading>
                                <Checkbox
                                  status={input.value ? "checked" : "unchecked"}
                                />
                              </View>
                            </Surface>
                          </TouchableRipple>
                        );
                      }}
                    </Field>

                    <Divider /> */}
                    <Field name="condition">
                      {({ input }) => (
                        <CheckedDropdown
                          label="Condition"
                          checkedItems={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </Surface>

                  <Surface style={styles.surface}>
                    {/* Dropdown menu with single select */}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        // height: 64,
                      }}
                    >
                      {/* Pickers countries theme */}

                      <Field name="locatedIn">
                        {({ input }) => (
                          <PickerCustom
                            label="From country"
                            value={input.value}
                            onChange={input.onChange}
                            items={CountryCodeTypeFrom}
                            theme={theme}
                          />
                        )}
                      </Field>
                      <Field name="availableTo">
                        {({ input }) => (
                          <PickerCustom
                            label="To country"
                            items={CountryCodeTypeTo}
                            value={input.value}
                            onChange={input.onChange}
                            theme={theme}
                          />
                        )}
                      </Field>
                    </View>

                    <Divider />
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Field name="site">
                          {({ input }) => (
                            <PickerCustom
                              label="Site"
                              value={input.value}
                              onChange={input.onChange}
                              items={eBaySites.map((site) => site.name)}
                              theme={theme}
                            />
                          )}
                        </Field>
                      </View>
                      <QueryTextInput
                        style={[styles.formInput, {}]}
                        name="zip"
                        theme={theme}
                        label="Ship Zip Code"
                      />
                    </View>
                  </Surface>
                  <Divider />

                  <Surface style={styles.surface}>
                    <Field name="sellerFilter">
                      {({ input }) => (
                        <PickerCustom
                          label="Seller filter"
                          value={input.value}
                          onChange={input.onChange}
                          items={["Disabled", "Include", "Exclude"]}
                          theme={theme}
                        />
                      )}
                    </Field>
                    <FieldCondition when="sellerFilter" isNot="Disabled">
                      <Divider />

                      <QueryTextInput
                        style={[styles.formInput, {}]}
                        name="sellerList"
                        label="Sellers"
                        placeholder={"seller1,seller2,seller3,...seller10"}
                      />
                    </FieldCondition>
                  </Surface>
                </List.Accordion>
                <Divider />
              </View>
            );
          }}
        />
      </ScrollView>
    </View>
  );
};

SearchQueryEdit.whyDidYouRender = true;
//export default withKeyboardAwareScrollView(SearchQueryEdit);
export default SearchQueryEdit;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
  },
  formInput: {
    margin: 6,
    backgroundColor: "transparent",
  },
  surface: {
    elevation: 2,
    margin: 10,
    borderRadius: 4,
  },
  button: {
    alignItems: "center",
    //backgroundColor: "#DDDDDD",
    //padding: 10,
  },
});
