import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { Button } from "react-native-paper";

import { firebaseApp } from "../firebase/firebase_config";

const VersionCheck = () => {
  const [showReloadButton, setShowReloadButton] = useState(false);

  useEffect(() => {
    const versionRef = firebaseApp.database().ref("app-settings/version");

    const handleDataChange = (snapshot: { val: () => any }) => {
      const currentVersion = Constants.expoConfig?.version;
      if (currentVersion === undefined) return;

      const remoteVersion = snapshot.val();
      if (isNewer(remoteVersion, currentVersion)) {
        setShowReloadButton(true);
      } else {
        setShowReloadButton(false);
      }
    };

    versionRef.on("value", handleDataChange);

    // Clean up the listener when the component is unmounted
    return () => versionRef.off("value", handleDataChange);
  }, []);

  const handleReload = () => {
    setShowReloadButton(false);
    window.location.reload();
  };

  return showReloadButton ? (
    <Button onPress={handleReload}>
      Website update is available. Click here to reload.
    </Button>
  ) : null;
};

function isNewer(newVersion: string, currentVersion: string) {
  const parts1 = newVersion.split(".").map(Number);
  const parts2 = currentVersion.split(".").map(Number);
  const maxLength = Math.max(parts1.length, parts2.length);

  for (let i = 0; i < maxLength; i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 > part2) return true;
    if (part1 < part2) return false;
  }

  return false;
}

export { VersionCheck };
