import * as Brightness from "expo-brightness";
import React, { useEffect, useState } from "react";
import { View } from "react-native";

import Slider from "@react-native-community/slider";

export const BrightnessSlider = ({ onSlidingComplete, value }: any) => {
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    (async () => {
      Brightness.setBrightnessAsync(sliderValue);
    })();
  }, [sliderValue]);

  function changeSlider(e: React.SetStateAction<number>) {
    setSliderValue(e);
  }

  return (
    <View style={{ flex: 1 / 1.2 }}>
      <Slider
        style={{}}
        value={sliderValue}
        step={0.01}
        minimumValue={0}
        maximumValue={1}
        onValueChange={(e) => changeSlider(e)}
        onSlidingComplete={onSlidingComplete}
      />
    </View>
  );
};
