import { CachePersistor } from "apollo3-cache-persist";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApolloClient } from "@apollo/client";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import { auth, EmailAuthProvider, firebaseApp } from "../firebase/firebase_config";
import { IAppState } from "../interfaces/AppState";
import { Platform } from "../Platform";
import { IRootReducer } from "../reducers/allReducers";
import { AsyncStorage } from "../Tools/Storage";

const useAuth = () => {
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const appState = useSelector<IRootReducer, IAppState>(
    (state) => state.appState
  );

  const signIn = async (email: any, password: any) => {
    try {
      const result = await auth().signInWithEmailAndPassword(email, password);

      onSignInSuccess(result.user);
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: "ADD_ERROR",
          payload: err.message,
        });
      }
      return err;
    }
    return {};
  };

  function onSignInSuccess(fbUser: any) {
    const user = {
      emailVerified: fbUser.emailVerified,
      isAnonymous: fbUser.isAnonymous,
      uid: fbUser.uid,
      email: fbUser.email,
    };
    dispatch({ type: "SET_USER", payload: user });
  }

  const signOut = async (client: ApolloClient<object> | null) => {
    const deviceID = appState.deviceID;
    await firebaseApp.database().goOffline();
    firebaseApp.database().ref(".info/connected").off();

    if (appState.user.isAnonymous) {
      dispatch({ type: "RESET" });
      dispatch({ type: "SET_DEVICE_ID", payload: deviceID });
      auth().currentUser?.delete();
      return;
    }

    await auth()
      .signOut()
      .then((response: any) => {
        console.log("Sign out", response);
      })
      .catch((error: any) => console.log("Sign out error", error));
    if (client?.cache) {
      const persistor = new CachePersistor({
        cache: client.cache,
        storage: Platform.OS == "web" ? window.localStorage : AsyncStorage,
      });
      await persistor.purge();
      await client.clearStore();
    }


    //dispatch({ type: "RESET" });
    dispatch({ type: "SET_USER", payload: {} });
    dispatch({ type: "CLEAR_PUSH_TOKEN" });
    dispatch({ type: "SET_DEVICE_ID", payload: deviceID });
  };

  const signUp = async (email: any, password: any) => {
    try {
      if (appState.user.isAnonymous) {
        const credential = EmailAuthProvider.credential(email, password);

        const convertedUser = await auth().currentUser?.linkWithCredential(
          credential
        );

        if (convertedUser) {
          onSignInSuccess(convertedUser.user);
        }
      } else {
        const newUser = await auth().createUserWithEmailAndPassword(
          email,
          password
        );
        onSignInSuccess(newUser.user);
      }
    } catch (err) {
      return err;
    }
    return {};
  };

  const tryAsGuest = async () => {
    try {
      const result = await auth().signInAnonymously();
      onSignInSuccess(result.user);
      navigation.navigate("Drawer");
    } catch (err) {
      console.log("tryAsGuest: ", err);
      return err;
    }
    return {};
  };

  const forgotPassword = useCallback(async (email: string) => {
    try {
      await auth().sendPasswordResetEmail(email);
    } catch (err) {
      return err;
    }
    return {};
  }, []);

  return { signIn, signOut, signUp, tryAsGuest, forgotPassword };
};
export { useAuth };
