import React from "react";
import { Platform, View, ViewStyle } from "react-native";
import { MD3Theme } from "react-native-paper";

import { Picker } from "@react-native-picker/picker";

export const PickerComponent = (props: {
  theme: MD3Theme;
  label: string;
  value: string;
  onChange: any;
  items: string[];
  style?: ViewStyle;
}) => {
  const webStyle =
    Platform.OS === "web"
      ? {
          paddingVertical: 12,
        }
      : {};
  const webDarkStyle =
    Platform.OS === "web" && props.theme.dark
      ? {
          backgroundColor: props.theme.colors.background,
          marginHorizontal: 10,
        }
      : {};

  return (
    <View style={{ justifyContent: "flex-end" }}>
      <Picker
        selectedValue={props.value}
        onValueChange={props.onChange}
        dropdownIconColor={props.theme.colors.onSurface}
        dropdownIconRippleColor={props.theme.colors.onSurface}
        style={{
          flex: 1,
          paddingVertical: 12,
          marginTop: 5,
          marginHorizontal: 5,
          borderWidth: 0,
          color: props.theme.colors.onSurface,
          // backgroundColor: "transparent",
          ...webStyle,
          ...webDarkStyle,
          ...props.style,
        }}
      >
        {props.items.map((c: string) => (
          <Picker.Item
            color={
              Platform.OS === "android" && props.theme.dark && props.value === c
                ? props.theme.colors.onTertiary
                : props.theme.colors.onSurface
            }
            style={{
              color: "black",
              backgroundColor:
                Platform.OS === "android" && props.value === c
                  ? "transparent"
                  : props.theme.colors.background,
            }}
            key={c}
            label={c}
            value={c}
          />
        ))}
      </Picker>
    </View>
  );
};
