import { useDispatch, useSelector } from "react-redux";

import { FS } from "../constants";
import { firebaseApp, ServerValue } from "../firebase/firebase_config";
import { IRootReducer } from "../reducers/allReducers";

export const useFirebase = () => {
  const dispatch = useDispatch();

  const userID = useSelector<IRootReducer, string>((state) => state.appState.user.uid);
  const deviceID = useSelector<IRootReducer, string | null>((state) => state.appState.deviceID); // Make sure the type can be null

  const searchStarted = useSelector<IRootReducer, boolean>(
    (state) => state.appState.searchStarted,
  );

  const userRef = firebaseApp.database().ref(`${FS.users}/${userID}`);

  // Only create deviceStateRef if deviceID is not null
  const deviceStateRef = deviceID ? firebaseApp.database().ref(`${FS.users}/${userID}/${FS.device}/${deviceID}`) : null;

  const toggleSearchStarted = () => {
    const updatedSearchStarted = !searchStarted;
    const searchState = {
      [`${FS.searchStarted}`]: updatedSearchStarted,
      [`${FS.timestamp}`]: ServerValue.TIMESTAMP,
    };
    dispatch({ type: "TOGGLE_SEARCH" });
    userRef
      .update(searchState, (error: any) => {
        if (error) dispatch({ type: "TOGGLE_SEARCH" });
      })
      .then()
      .catch();
  };

  const sendVisibilityState = (state: string) => {
    // Only execute if deviceStateRef is valid
    if (deviceStateRef) {
      const visibilityState = { t: ServerValue.TIMESTAMP, v: state };
      deviceStateRef.update(visibilityState).then().catch();
    } else {
      console.warn("Attempted to send visibility state without a valid deviceID");
    }
  };

  return { toggleSearchStarted, sendVisibilityState };
};
