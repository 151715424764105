import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ReduxThunk from "redux-thunk";

import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

import { messaging } from "./src/firebase/firebase_config";
import { App1 } from "./src/MainComponent";
import {
  createAndroidChannel,
  ShowNativeNotification,
  subscribeToClickFromBackground,
} from "./src/Messaging/NotificationService";
import { Platform } from "./src/Platform";
import { combinedReducers } from "./src/reducers/allReducers";
import { AsyncStorage } from "./src/Tools/Storage";

if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}
if (Platform.OS != "web") {
  messaging().setBackgroundMessageHandler(async (message) => {
    await ShowNativeNotification(message);
  });

  subscribeToClickFromBackground();
  createAndroidChannel();
}

let composeEnhancers = compose;
if (__DEV__) {
  //const whyDidYouRender = require("@welldone-software/why-did-you-render");
  //whyDidYouRender(React, { trackAllPureComponents: true });
  //@ts-ignore
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(ReduxThunk)),
);

const persistor = persistStore(store, null, () => {});

//@ts-ignore
const App = (param) => {
  if (param?.isHeadless) {
    // App has been launched in the background by iOS, ignore
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <App1 />
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};
export default App;
