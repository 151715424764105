import React from "react";
import { Platform } from "../../Platform";
import { FAB } from "react-native-paper";

export const FABCustom = (props: any) => {
  const fabLocation = Platform.OS == "web" ? { top: 12 } : { bottom: 30 };
  return (
    <FAB
      style={{
        position: "absolute",
        margin: 16,
        right: 30,
        zIndex: 10,
        ...fabLocation,
      }}
      disabled={props.disabled}
      label={props.label}
      icon={props.icon}
      onPress={props.onPress}
    />
  );
};
