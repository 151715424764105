import React from "react";
import { Field } from "react-final-form";
import { StyleSheet, View } from "react-native";
import { HelperText, TextInput } from "react-native-paper";

export const QueryTextInput = (props: any) => {
  return (
    <View style={{ flex: 1 }}>
      <Field name={props.name} parse={(value) => (value ? value : "")}>
        {({ input, meta }) => (
          <View>
            <TextInput
              onChangeText={input.onChange}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              value={input.value.toString()}
              {...props}
            />

            {meta.error && meta.touched && (
              <HelperText type="error" visible={meta.touched && meta.error}>
                {meta.error}
              </HelperText>
            )}
          </View>
        )}
      </Field>
    </View>
  );
};

export const QueryNumInput = (props: any) => {
  return (
    <View style={{ flex: 1 }}>
      <Field
        name={props.name}
        parse={(value) =>
          !parseFloat(value) || !Number(value) || value.endsWith(".")
            ? value
            : parseFloat(value)
        }
      >
        {({ input, meta }) => (
          <View>
            <TextInput
              onChangeText={input.onChange}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              value={input.value.toString()}
              {...props}
            />

            {meta.error && meta.touched && (
              <HelperText type="error" visible={meta.touched && meta.error}>
                {meta.error}
              </HelperText>
            )}
          </View>
        )}
      </Field>
    </View>
  );
};

export const styles = StyleSheet.create({
  textInput: {
    margin: 6,
  },
});
