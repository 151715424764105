import { Platform } from "../Platform";
import { openUrl } from "../Tools/openUrl";
import { eBaySites } from "./eBaySite";

const dustinTest1Camp = "5337451737";
const dustinTest2Camp = "5337451738";
const iphonesCamp = "5337730826";
const ebayphoneresearch = "5337472029";

const newcheckoutCamp = "5337632653";
//Promotions default campaign(5337632653)
export const OpenAffiliateLink = (
  userID: string,
  itemID: string,
  siteDomain: string,
) => {
  const url = GetAffiliateUrl(userID, itemID, siteDomain);

  if (Platform.OS === "android") {
    //preloadUrl(url);
    openUrl(url);
  } else {
    openUrl(url);
  }
};

const preloadUrl = (url: string) => {
  const http = new XMLHttpRequest();
  http.open("GET", url);
  http.setRequestHeader(
    "User-Agent",
    "Mozilla/5.0 (Linux; Android 10) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.81 Mobile Safari/537.36",
  );
  http.setRequestHeader("accept-encoding", "identity"); //disable gzip
  http.onreadystatechange = function () {
    if (this.readyState === this.DONE) {
      const regex = /pagesURL\" : \"(http.*)"/;
      const urlMatch = this.responseText.match(regex) || [""];
      if (urlMatch[1]) {
        const affiliateUrl = urlMatch[1];
        if (urlMatch[1]) openUrl(affiliateUrl);
      }
    }
  };

  http.send();
};

const GetAffiliateUrl = (
  userID: string,
  itemID: string,
  siteDomain: string
): string => {

  const site = eBaySites.find((site) => site.domain.indexOf(siteDomain) >= 0) || eBaySites[0];

  let url = `https://${site.domain}/itm/${itemID}`;
  const regex = /rover\/1\/([0-9-]+)\//;
  const found = site.roverlink.match(regex);
  let rotationID = "711-53200-19255-0";
  if (found && found.length > 0)
    rotationID = found[1];

  const mkevt = "?mkevt=1";
  const mkcid = "&mkcid=1";
  const mkrid = "&mkrid=" + rotationID;
  const campid = "&campid=" + newcheckoutCamp;
  const toolid = "&toolid=" + "10001";
  const customid = "&customid=" + `${itemID};${userID};${"SN"};${Platform.OS}`;

  url += mkevt;
  url += mkcid;
  url += mkrid;
  url += campid;
  url += toolid;
  url += customid;




  switch (Platform.OS) {
    case "ios":
      return url.replace("5337536011", iphonesCamp);
    case "web":
      return url.replace("5337536011", dustinTest2Camp);
    case "android":
      return url.replace("5337536011", dustinTest1Camp);
    default:


      return url;
  }
};


const GetAffiliateUrlOLD = (
  userID: string,
  itemID: string,
  siteDomain: string
): string => {

  const site = eBaySites.find((site) => site.domain.indexOf(siteDomain) >= 0) || eBaySites[0];
  var affiliateUrl = site.roverlink;
  affiliateUrl += `&item=${itemID}`;
  affiliateUrl += "&toolid=10001";
  affiliateUrl += `&mpre=${encodeURI(`https://${site.domain}/itm/${itemID}`)}`; //TODO: test on co.uk
  affiliateUrl += `&customid=${itemID};${userID};${"SN"};${Platform.OS}`;

  switch (Platform.OS) {
    case "ios":
      return affiliateUrl.replace("5337536011", iphonesCamp);
    case "web":
      return affiliateUrl.replace("5337536011", dustinTest2Camp);
    case "android":
      //return affiliateUrl.replace("5337536011", Promotionsdefaultcampaign);
      return affiliateUrl.replace("5337536011", dustinTest1Camp);

    default:
      var affiliateUrl =
        `https://m.ebay.com/itm/item/${itemID}` +
        "?ul_ref=https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2" +
        "&lgeo=1&toolid=10001" +
        `&campid=${dustinTest1Camp}` +
        `&vectorid=229466&item=${itemID}` +
        `&customid=${itemID}ReactNative` +
        "&mpre=https%3A%2F%2Fwww.ebay.com%2Fitm%2F" +
        itemID +
        "&srcrot=711-53200-19255-0" +
        "&rvr_id=2253200882093" +
        "&rvr_ts=8e68ef6716f0ac3cf9e4c550fffe457d" +
        "&_mwBanner=1" +
        "&_rdt=1" +
        "&ul_noapp=true" +
        "&pageci=d57aebd7-8b68-4497-b780-45218c0b0a3d" +
        "&epid=168553410";

      //"&mpre=https%3A%2F%2Fwww.ebay.com%2Fitm%2F143425176110&srcrot=711-53200-19255-0&rvr_id=2225400065152&rvr_ts=1436b77d16f0a9cb9dc29ae2fff48fc8&_mwBanner=1&_rdt=1&ul_noapp=true&pageci=455de3f1-69a9-4155-b648-9dfe18f2622b";
      return affiliateUrl;
  }
};
