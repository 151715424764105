import { appStateAction, IAppState } from "../interfaces/AppState";

const initialState: IAppState = {
  searchStarted: false,
  notificationsEnabled: true,
  user: { email: "", emailVerified: false, isAnonymous: false, uid: "" },
  errorMessage: "",
  deviceID: "",
  pushToken: "",
  soundFile: "notification.mp3",
  soundEnabled: true,
  resultsNumber: "20",
  searchStartedTime: 0,
  cardSize: 5000,
  viewType: "row",
  screenTimeout: 0,
  brightnessEnabled: false,
  brightnessLevel: 0.5,
};

const appStateReducer = (
  state: IAppState = initialState,
  action: appStateAction,
): IAppState => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };

    case "SET_DEVICE_ID":
      return { ...state, deviceID: action.payload };
    case "SET_PUSH_TOKEN":
      return { ...state, pushToken: action.payload };
    case "CLEAR_PUSH_TOKEN":
      return { ...state, pushToken: "" };
    case "SET_SEARCH_START_TIME":
      return { ...state, searchStartedTime: action.payload };
    case "START_SEARCH":
      return { ...state, searchStarted: true };
    case "STOP_SEARCH":
      return { ...state, searchStarted: false };
    case "TOGGLE_SEARCH":
      return { ...state, searchStarted: !state.searchStarted };
    case "ENABLE_NOTIFICATIONS":
      return { ...state, notificationsEnabled: true };
    case "DISABLE_NOTIFICATIONS":
      return { ...state, notificationsEnabled: false };
    case "TOGGLE_NOTIFICATIONS":
      return { ...state, notificationsEnabled: !state.notificationsEnabled };
    case "ADD_ERROR":
      return { ...state, errorMessage: action.payload };
    case "SIGN_IN":
      return { ...state, errorMessage: "", user: action.payload };
    case "SET_SOUND_FILE":
      return { ...state, soundFile: action.payload };
    case "SET_SOUND_ENABLED":
      return { ...state, soundEnabled: action.payload };
    case "SET_RESULTS_NUMBER":
      return { ...state, resultsNumber: action.payload };
    case "SET_CARD_SIZE":
      return { ...state, cardSize: action.payload };
    case "SET_VIEW_TYPE":
      return { ...state, viewType: action.payload };
    case "SET_SCREEN_TIMEOUT":
      return { ...state, screenTimeout: action.payload };
    case "SET_BRIGHTNESS_ENABLED":
      return { ...state, brightnessEnabled: action.payload };
    case "SET_BRIGHTNESS_LEVEL":
      return { ...state, brightnessLevel: action.payload };

    case "CLEAR_ERROR":
      return { ...state, errorMessage: "" };

    default:
      return state;
  }
};
export { appStateReducer };

export const toggleSearchState = () => ({ type: "TOGGLE_SEARCH" });
