import Qs, { ParsedQs } from "qs";
import React, { Component } from "react";
import {
  ActivityIndicator,
  createElement,
  StyleSheet,
  View,
} from "react-native";

export class WebView extends Component {
  static defaultProps = {
    scrollEnabled: true,
  };

  state = { html: null };
  // @ts-ignore
  constructor(props) {
    super(props);
    this.handleSource(props.source, props.newWindow);
  }
  // @ts-ignore
  setRef = (ref) => (this.frameRef = ref); // @ts-ignore

  handleSource = (source, newWindow) => {
    if (!source.method) return;

    if (newWindow) {
      this.handleSourceInNewWindow(source, newWindow);
    } else {
      this.handleSourceInIFrame(source);
    }
  };
  // @ts-ignore
  handleSourceInIFrame = (source) => {
    const { uri, ...options } = source;
    const baseUrl = uri.substr(0, uri.lastIndexOf("/") + 1);
    fetch(uri, options)
      .then((response) => response.text())
      .then((html) =>
        this.setState({ html: `<base href="${baseUrl}" />` + html }),
      );
  };
  // @ts-ignore
  handleSourceInNewWindow = (source, newWindow) => {
    if (source.method === "POST") {
      const contentType = source.headers["Content-Type"];
      let body: ParsedQs;
      if (
        contentType &&
        contentType.includes("application/x-www-form-urlencoded")
      ) {
        body = Qs.parse(source.body);
      } else {
        console.warn(
          "[WebView] When opening a new window, this content-type is not supported yet, please make a PR!",
          contentType,
        );
        return;
      }

      //   window.open(
      //     require("./postMock.html") +
      //       "?" +
      //       Qs.stringify({
      //         uri: source.uri,
      //         body: JSON.stringify(body),
      //       }),
      //     newWindow.name || "webview",
      //     newWindow.features || undefined,
      //   );
      // } else {
      //   console.warn(
      //     "[WebView] When opening a new window, this method is not supported yet, please make a PR!",
      //     source.method,
      //   );
    }
  };

  componentDidMount() {
    // @ts-ignore
    if (this.props.onMessage) {
      // @ts-ignore
      window.addEventListener("message", this.onMessage, true);
    }
  }

  // @ts-ignore
  componentWillReceiveProps(nextProps) {
    if (
      // @ts-ignore
      this.props.source.uri !== nextProps.source.uri || // @ts-ignore
      this.props.source.method !== nextProps.source.method || // @ts-ignore
      this.props.source.body !== nextProps.source.body
    ) {
      this.handleSource(nextProps.source, nextProps.newWindow);
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    if (this.props.onMessage) {
      // @ts-ignore
      window.removeEventListener(
        "message", // @ts-ignore
        this.onMessage,
        true,
      );
    }
  }

  // @ts-ignore         onMessage = (nativeEvent) => this.props.onMessage({ nativeEvent });

  postMessage = (message, origin) => {
    // @ts-ignore
    this.frameRef.contentWindow.postMessage(message, origin);
  };
  // @ts-ignore
  handleInjectedJavaScript = (html) => {
    // @ts-ignore
    if (
      // @ts-ignore
      this.props.injectedJavaScript
    ) {
      if (html) {
        return html.replace(
          "</body>", // @ts-ignore
          `<script>${this.props.injectedJavaScript}</script></body>`,
        );
      } else {
        return html;
      }
    } else {
      return html;
    }
  };

  render() {
    // @ts-ignore
    if (this.props.newWindow) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator />
        </View>
      );
    }
    // @ts-ignore
    const { title, source, onLoad, scrollEnabled } = this.props; // @ts-ignore
    const styleObj = StyleSheet.flatten(this.props.style);
    return createElement("iframe", {
      title,
      ref: this.setRef,
      src: !source.method ? source.uri : undefined,
      srcDoc: this.handleInjectedJavaScript(this.state.html || source.html),
      width: styleObj && styleObj.width,
      height: styleObj && styleObj.height,
      style: [
        styles.iframe,
        scrollEnabled && styles.noScroll, // @ts-ignore
        this.props.style,
      ],
      allowFullScreen: true,
      allowpaymentrequest: "true",
      frameBorder: "0",
      seamless: true,
      onLoad,
    });
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iframe: {
    width: "100%",
    height: "100%",
    borderWidth: 0,
  },
  noScroll: {
    overflow: "hidden",
  },
});
