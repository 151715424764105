import React from "react";
import { SafeAreaView } from "react-native";
import { Autocomplete } from "react-native-dropdown-autocomplete";
import { useTheme } from "react-native-paper";

import { eBaySites } from "../Geo/eBaySite";
import { Platform } from "../Platform";

interface Category {
  categoryId: string;
  categoryName: string;
}

interface CategorySuggestion {
  category: Category;
  categoryTreeNodeLevel: number;
  categoryTreeNodeAncestors: Category[];
}

const CategoryAutocomplete = ({
  siteName,
  onChange,
  selectedCategories,
  setTextEditVisible,
}: any) => {
  const theme = useTheme();
  const spinnerPlaceholderStyle =
    Platform.OS === "android" ? { marginLeft: 10 } : { marginLeft: 25 };

  return (
    <SafeAreaView>
      <Autocomplete
        fetchDataUrl={"https://ubuyfirst.com/uBuyFirst/categories.php?q="}
        spinnerStyle={{
          top: "25%",
          left: 3,
          position: "absolute",
        }}
        inputStyle={{
          color: theme.colors.onSurface,
          backgroundColor: "transparent",
          borderBottomWidth: 0,
          borderBottomColor: "grey",
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          width: "100%",
          paddingLeft: 12,
          fontSize: undefined,
          ...spinnerPlaceholderStyle,
        }}
        //@ts-ignore
        listItemTextStyle={{
          //category leaf
          alignSelf: "flex-start",
          maxWidth: "90%",
          fontWeight: "bold",
          color: theme.colors.onSurface,
        }}
        scrollStyle={{
          backgroundColor: theme.colors.background,
        }}
        pickerStyle={{
          //dropdown
          marginTop: 20,
          //borderBottomColor: "transparent",
          width: "90%",
        }}
        rightContentItemStyle={{
          //category path
          textDecorationLine: "none",
          alignSelf: "flex-start",
        }}
        rightContentStyle={{
          alignSelf: "flex-start",
        }}
        handleSelectItem={(categorySuggestion: CategorySuggestion) => {
          setTextEditVisible(false);

          const selectedCategory = {
            name: categorySuggestion.category.categoryName,
            id: categorySuggestion.category.categoryId,
          };
          const categoryExists = selectedCategories.some(
            (c: any) => c.id == selectedCategory.id,
          );
          if (!categoryExists) {
            const serializedC = JSON.stringify([
              ...selectedCategories,
              selectedCategory,
            ]);
            onChange(serializedC);
          }
        }}
        itemPadding={30} //item height
        minimumCharactersCount={0}
        highlightText
        valueExtractor={(item: { category: Category }) => {
          if (item && item.category) {
            return (
              item.category.categoryName + " (" + item.category.categoryId + ")"
            );
          } else {
            return "";
          }
        }}
        placeholder={"Find category by name or id"}
        rightContent={true}
        rightTextExtractor={(category: CategorySuggestion) => {
          if (category && category.categoryTreeNodeAncestors) {
            var ids = category.categoryTreeNodeAncestors.map(
              ({ categoryName, categoryId }) =>
                categoryName + " (" + categoryId + ")",
            );
            ids.reverse();
            var path = ids.join(" -> ");
            return path;
          }
          return "";
        }}
        resetOnSelect={true}
        waitInterval={300}
        listHeader={"Category Name (Category ID)"}
      />
    </SafeAreaView>
  );
};

export { CategoryAutocomplete };
