import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Button,
  Divider,
  IconButton,
  Paragraph,
  Subheading,
  Switch,
  TouchableRipple,
  useTheme,
} from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";

import { useApolloClient } from "@apollo/client";

import { ScreenHeader } from "../Auth/ScreenHeader";
import { PickerCustom } from "../components/DropDowns/PickerCustom";
import { useAuth } from "../hooks/useAuth";
import { IUser } from "../interfaces/AppState";
import { openNotificationSettings } from "../Messaging/openNotificationSettings";
import { Platform } from "../Platform/index";
import { IRootReducer } from "../reducers/allReducers";
import { CardWidthSlider } from "../SearchResults/CardWidthSlider";
import { BackgroundNotificationsComponent } from "../Settings/BackgroundNotificationsComponent";
import { BrightnessComponent } from "../Settings/BrightnessComponent";

const SettingsComponent = ({ navigation }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const client = useApolloClient();
  const onPressNotificationSettings = openNotificationSettings();
  const user = useSelector<IRootReducer, IUser>((state) => state.appState.user);
  const resultsNumberRedux = useSelector<IRootReducer, string>(
    (state) => state.appState.resultsNumber,
  );
  const screenTimeout = useSelector<IRootReducer, number>(
    (state) => state.appState.screenTimeout,
  );

  const updateResultsNumber = (e: string) => {
    dispatch({ type: "SET_RESULTS_NUMBER", payload: e });
  };

  const resultsNumberOptions = ["10", "20", "30", "50", "100"];

  const NotificationButton = (props: any) => (
    <TouchableOpacity
      onPress={props.onPress}
      style={{
        paddingVertical: 7,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Subheading>Notification settings</Subheading>
      <IconButton
        size={27}
        icon={"bell-outline"}
        style={{
          marginVertical: 0,
          opacity: 0.6,
        }}
      />
    </TouchableOpacity>
  );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      <ScreenHeader action="back" title="Settings" navigation={navigation} />

      <ScrollView style={{ backgroundColor: theme.colors.background }}>
        <View
          style={{
            borderRadius: 4,
            marginHorizontal: 10,
            paddingHorizontal: 10,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ justifyContent: "center" }}>
              <PickerCustom
                label="Results limit"
                items={resultsNumberOptions}
                value={resultsNumberRedux}
                onChange={updateResultsNumber}
                theme={theme}
              />

              {Platform.OS != "web" && (
                <>
                  <Divider />
                  <NotificationButton onPress={onPressNotificationSettings} />

                  {Platform.OS === "android" && (
                    <>
                      <Divider />
                      <BackgroundNotificationsComponent
                        navigation={navigation}
                      />
                    </>
                  )}
                  <Divider />
                  <TouchableRipple
                    onPress={() => {
                      dispatch({
                        type: "SET_SCREEN_TIMEOUT",
                        payload: screenTimeout === 0 ? 1 : 0,
                      });
                    }}
                  >
                    <View style={styles.preference}>
                      <Subheading>Keep screen ON</Subheading>
                      <View pointerEvents="none">
                        <Switch value={screenTimeout > 0} />
                      </View>
                    </View>
                  </TouchableRipple>
                  <Divider />
                  <BrightnessComponent />
                </>
              )}
            </View>
          </View>
          <Divider />
          {Platform.OS === "web" ? (
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Subheading
                  style={{ alignSelf: "flex-start", paddingVertical: 15 }}
                >
                  Item card width
                </Subheading>
                <CardWidthSlider />
              </View>
              <Divider />
            </>
          ) : null}

          <View>
            {!user.isAnonymous ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Subheading
                  style={{ alignSelf: "flex-start", paddingVertical: 15 }}
                >
                  Profile email
                </Subheading>
                <Subheading
                  style={{ alignSelf: "flex-start", paddingVertical: 15 }}
                >
                  {user.email}
                </Subheading>
              </View>
            ) : (
              <Paragraph style={{ alignSelf: "center", paddingVertical: 15 }}>
                Guest account. Sign out to delete your data.
              </Paragraph>
            )}

            <Divider />
            <Button
              style={{ marginTop: 10, marginBottom: 20, alignSelf: "center" }}
              mode="contained"
              onPress={() => signOut(client)}
            >
              Sign out
            </Button>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 12,
    alignItems: "center",
  },
});
export default SettingsComponent;
