import { FORM_ERROR } from "final-form";
import React from "react";
import { Form, FormSpy } from "react-final-form";
import { Platform, StyleSheet, View } from "react-native";
import { Button, HelperText } from "react-native-paper";
import * as Yup from "yup";

import { useAuth } from "../hooks/useAuth";
import { LightTheme } from "../Theme/LightTheme";
import { makeValidate } from "../Tools/validation";
import { LoginPassword } from "./LoginPassword";

export const SignUpForm = () => {
  const { signUp } = useAuth();
  return <SignForm onSubmit={signUp} buttonText="Sign up" />;
};

export const SignForm = (props: any) => {
  const schema = Yup.object({
    email: Yup.string().email().required().label("Email"),
    password: Yup.string().required().min(6).label("Password"),
  });

  const validate = makeValidate(schema);
  const onSubmit = async (values: any) => {
    const error = await props.onSubmit(values.email, values.password);
    if (error) {
      return { [FORM_ERROR]: error.message };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={validate}
      validateOnBlur={false}
      render={({ handleSubmit, submitting }) => {
        return (
          //@ts-ignore
          <View accessibilityRole={Platform.OS != "web" ? "none" : "form"}>
            <LoginPassword handleSubmit={handleSubmit}></LoginPassword>

            <FormSpy>
              {({ hasSubmitErrors, submitError }) =>
                hasSubmitErrors && (
                  <HelperText
                    type="error"
                    visible={hasSubmitErrors && submitError}
                  >
                    {submitError}
                  </HelperText>
                )
              }
            </FormSpy>
            <Button
              disabled={submitting}
              loading={submitting}
              style={styles.button}
              theme={LightTheme}
              mode="contained"
              onPress={handleSubmit}
            >
              {props.buttonText}
            </Button>
          </View>
        );
      }}
    />
  );
};

export const styles = StyleSheet.create({
  button: {
    margin: 5,
    alignSelf: "center",
  },
});
