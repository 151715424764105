import Constants from "expo-constants";
import React from "react";
import { View } from "react-native";
import { Subheading, Surface, Text, useTheme } from "react-native-paper";

import { ScreenHeader } from "../Auth/ScreenHeader";

const AboutScreen = ({ navigation }: any) => {
  const theme = useTheme();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        alignItems: "stretch",
      }}
    >
      <ScreenHeader action="back" title="About" navigation={navigation} />

      <View
        style={{
          flexDirection: "row",
          margin: 16,
          justifyContent: "center",
        }}
      >
        <Surface
          style={{
            borderRadius: 4,
            marginHorizontal: 10,
            padding: 16,
            flexBasis: "100%",
          }}
        >
          <Subheading style={{ alignSelf: "center", padding: 20 }}>
            Changelog
          </Subheading>

          <Text>ver. 2023.12.06</Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
        </Surface>
      </View>
    </View>
  );
};

export default AboutScreen;
