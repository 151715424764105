import React, { useEffect, useState } from "react";
import { Dimensions, SafeAreaView, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useTheme } from "react-native-paper";

import { CloseButton } from "../Images/CloseGalleryButton";
import { FullScreenGallery } from "../Images/FullScreenGallery";
import { MainImage } from "../Images/MainImage";
import { ThumbnailRow } from "../Images/ThumbnailRow";
import { ItemProperties } from "./ItemProperties";
import { WebViewAutoHeight } from "./WebViewAutoHeight";

export const ItemDetailsScreen = (props: any) => {
  const node = props.route.params.node;
  const images = props.route.params.images;
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [index, setIndex] = useState(0);
  const [isGalleryFullScreen, setIsGalleryFullScreen] = useState(false);
  const theme = useTheme();
  const descriptionUri = `https://vi.vipr.ebaydesc.com/ws/eBayISAPI.dll?ViewItemDescV4&item=${node?.item?.itemID}`;

  const size = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };

  const clickClose = () => {
    setShowThumbnails(false);
    props.navigation.goBack();
    setIndex(0);
  };

  const onImageLoaded = () => {
    if (!showThumbnails && index == 0) {
      setShowThumbnails(true);
    }
  };

  const onImageClick = () => {
    setIsGalleryFullScreen(true);
  };

  useEffect(() => {
    const canUseDOM = !!(
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    );
    if (canUseDOM) {
      const closeOnEscape = (e: KeyboardEvent) => {
        if (!isGalleryFullScreen && e.key === "Escape") {
          e.stopPropagation();
          clickClose();
        }
      };
      document.addEventListener("keyup", closeOnEscape, false);
      return () => document.removeEventListener("keyup", closeOnEscape, false);
    }
  }, [isGalleryFullScreen]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "transparent" }}>
      <ScrollView
        style={{
          borderRadius: 4,
          flex: 1,
          minHeight: size.height,
          backgroundColor: theme.colors.background,
        }}
      >
        <CloseButton action={clickClose} location={{ top: 0, left: 0 }} />
        <MainImage
          images={images}
          index={index}
          setIndex={setIndex}
          onClick={onImageClick}
          onLoadEnd={onImageLoaded}
          imageHeight={size.height / 2}
        />
        {images && images.length > 1 && (
          <ThumbnailRow
            images={showThumbnails ? images : []}
            currentIndex={index}
            setIndex={setIndex}
          />
        )}
        {<ItemProperties node={node} />}
        <View style={{ backgroundColor: "white" }}>
          <WebViewAutoHeight source={{ uri: descriptionUri }} />
        </View>
        <FullScreenGallery
          isOpen={isGalleryFullScreen}
          images={images}
          imageHeight={size.height}
          currentIndex={index}
          setIndex={setIndex}
          onClose={() => setIsGalleryFullScreen(false)}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
