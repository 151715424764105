import { combineReducers } from "redux";

import { IAppState } from "../interfaces/AppState";
import { appStateReducer } from "./appStateReducer";

const rootReducer = combineReducers({
  appState: appStateReducer,
});

export interface IRootReducer {
  appState: IAppState;
}
export const combinedReducers = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }

  return rootReducer(state, action);
};
