import React, { useState } from "react";
import { View } from "react-native";
import { Button, HelperText, Surface, TextInput } from "react-native-paper";

import { useAuth } from "../hooks/useAuth";

const ForgotPasswordScreen = ({ navigation }: any) => {
  const { forgotPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [infoText, setInfoText] = useState("");

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Surface
        style={{
          elevation: 9,
          width: 300,
        }}
      >
        <TextInput
          value={email}
          label="Email"
          onChangeText={(text) => {
            setErrorText("");
            setInfoText("");
            setEmail(text.trim());
          }}
          textContentType="emailAddress"
          style={{ margin: 30 }}
        />

        {errorText != "" && (
          <HelperText
            style={{ marginHorizontal: 30, alignSelf: "center" }}
            type="error"
          >
            {errorText}
          </HelperText>
        )}
        {infoText != "" && (
          <HelperText
            style={{ marginHorizontal: 30, alignSelf: "center" }}
            type="info"
          >
            {infoText}
          </HelperText>
        )}

        <Button
          style={{ margin: 10, alignSelf: "center" }}
          mode="contained"
          onPress={async () => {
            setErrorText("");
            setInfoText("");
            if (!email.trim()) {
              return;
            }
            const errorObj = await forgotPassword(email.trim());
            if (errorObj instanceof Error) {
              if (errorObj.message) {
                setErrorText(errorObj.message);
              } else {
                setInfoText("Success! Please, check your inbox.");
              }
            } else {
            }
          }}
        >
          Reset password
        </Button>

        <Button
          style={{ margin: 30 }}
          mode="text"
          onPress={() => navigation.navigate("SignIn")}
        >
          Go back
        </Button>
      </Surface>
    </View>
  );
};

export default ForgotPasswordScreen;
