import { StatusBar } from "expo-status-bar";
import React from "react";
import { Image, Platform, View } from "react-native";
import { Appbar, useTheme } from "react-native-paper";

//@ts-ignore
import RightNowGif from "../Images/rightnow.gif";
import { NewSearchButton } from "../Queries/NewSearchButton";
import { QueryListMenu } from "../Queries/QueryListMenu";
import { DeleteAllResultsButton } from "./DeleteAllResultsButton";
import { ToggleNotificationButton } from "./ToggleNotificationsButton";
import { ToggleSearchButton } from "./ToggleSearchButton";

export const ScreenHeader = (props: any) => {
  const theme = useTheme();
  return (
    <Appbar.Header
      collapsable={true}
      mode="small"
      //statusBarHeight={Platform.OS === "android" ? 0 : undefined}
      style={{
        backgroundColor: theme.colors.primary,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {props.action === "back" ? (
          <Appbar.BackAction
            //icon={"arrow-left"}
            onPress={() => props.navigation.goBack()}
            color={"white"}
          />
        ) : props.action === "drawer" ? (
          <Appbar.Action
            isLeading
            icon={"menu"}
            onPress={() => {
              props.navigation.toggleDrawer();
            }}
            color={"white"}
          />
        ) : (
          <Appbar.Action
            icon={"close"}
            onPress={() => props.navigation.goBack()}
            color={"white"}
          />
        )}
        {props.title && (
          <Appbar.Content style={{ flex: 0 }} title={props.title} />
        )}
      </View>
      <View style={{ flexDirection: "row" }}>
        {!props.title && (
          <Image
            source={RightNowGif}
            style={{
              width: 70,
              height: 40,
              borderRadius: 5,
            }}
            resizeMode="stretch"
          />
        )}
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!props.title && (
          <>
            <ToggleNotificationButton
              toggleNotifyPermissionVisible={
                props.toggleNotifyPermissionVisible
              }
            />
            <DeleteAllResultsButton />
            <ToggleSearchButton />
          </>
        )}

        {props.title === "eBay Searches" && (
          <>
            <NewSearchButton />
            {Platform.OS === "web" && <QueryListMenu />}
          </>
        )}
      </View>
      <StatusBar style={"light"} />
    </Appbar.Header>
  );
};
