export interface queryAction {
  query: Query;
  type: string;
}
export interface Query {
  enabled: boolean;
  action: string;
  alias: string; //textInput
  availableTo: string; //dropDown
  categories: string; //lookup
  categoriesName: string;
  condition: string; //dropDown checked
  locatedIn: string; //dropDown
  id: string;
  keyword: string; //textInput
  listingType: string; //dropDown checked
  minFeedbackCount: number; //numeric up down
  minFeedbackPercent: number; //numeric up down
  priceMax: number; //numeric up down
  priceMin: number; //numeric up down
  searchInDescription: boolean; //switch
  sellerList: string; //textInput
  sellerFilter: string; //switch
  site: string; //dropDown
  zip: string; //textInput
}

export interface minQuery {
  e: boolean;
  a: string; //textInput
  aT: string; //dropDown
  c: string; //lookup
  co: string; //dropDown checked
  l: string; //dropDown
  id: string;
  k: string; //textInput
  lT: string; //dropDown checked
  f: string; //numeric up down
  fp: string; //numeric up down
  p: string; //numeric up down
  pm: string; //numeric up down
  d: boolean; //switch
  sellerList: string; //textInput
  sellerInclude: string; //switch
  s: string; //dropDown
  z: string; //textInput
}

export const DefaultQuery: Query = {
  enabled: true,
  action: "",
  alias: "New search",
  availableTo: "US",
  keyword: "",
  searchInDescription: false,
  categories: "",
  categoriesName: "",
  priceMin: 1,
  priceMax: 10000,
  minFeedbackCount: 0,
  minFeedbackPercent: 0,
  site: "eBay US",
  condition: "",
  listingType: "BIN",
  locatedIn: "Any",
  zip: "10001",
  sellerList: "",
  sellerFilter: "Disabled",
  id: "",
};
