import React, { useEffect, useState } from "react";
import { Menu } from "react-native-paper";

type MenuWithTimeoutProps = {
  visible: boolean;
  onDismiss: () => void;
  anchor: React.ReactNode;
  timeout?: number;
  children: React.ReactNode;
};

const MenuWithTimeout = ({
  visible,
  onDismiss,
  anchor,
  timeout = 500, // default timeout
  children,
}: MenuWithTimeoutProps): React.JSX.Element => {
  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    if (visible) {
      setCanClose(false);
      const timer = setTimeout(() => setCanClose(true), timeout);
      return () => clearTimeout(timer); // Clear the timeout if the component is unmounted
    }
  }, [visible, timeout]);

  const handleDismiss = () => {
    if (canClose) {
      onDismiss();
    }
  };

  return (
    <Menu visible={visible} onDismiss={handleDismiss} anchor={anchor}>
      {children}
    </Menu>
  );
};

export default MenuWithTimeout;
