const cancelAllNotifications = () => { };
const ShowNativeNotification = () => { };
const getInitialNotification = () => { };
const subscribeToNotificationOpened = () => { };
const notificationChannelID = "";
export {
  ShowNativeNotification,
  cancelAllNotifications,
  getInitialNotification,
  subscribeToNotificationOpened,
  notificationChannelID,
};
