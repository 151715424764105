import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ProgressBar, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";

import { ApolloProvider } from "@apollo/client";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { getDeviceID } from "../Auth/DeviceID";
import ForgotPasswordScreen from "../Auth/ForgotPasswordScreen";
import { SignInScreen } from "../Auth/SignInScreen";
import { SignUpScreen } from "../Auth/SignUpScreen";
import { useFirebaseAuth } from "../Auth/useFirebaseAuth";
import { ThemeProvider } from "../contexts/ThemeContext";
import { useApolloClientInit } from "../Graphql/initApolloClient";
import useAsyncStorage from "../hooks/useAsyncStorage";
import { QueryBackup } from "../Queries/QueryBackup";
import QueryListScreen from "../Queries/QueryList";
import SearchQueryEditScreen from "../Queries/SearchQueryEdit";
import { IRootReducer } from "../reducers/allReducers";
import AboutScreen from "../Screens/AboutScreen";
import BatteryScreen from "../Screens/BatteryScreen";
import SettingsScreen from "../Screens/SettingsScreen";
import HomeScreen from "../SearchResults/HomeScreen";
import { ItemDetailsScreen } from "../SearchResults/ItemDetailsScreen";
import { BlankScreen } from "../Theme/Background";
import DrawerItems from "./DrawerItems";

const AuthStack = createStackNavigator();
export const AuthStackScreen = () => (
  <AuthStack.Navigator
    screenOptions={{ headerShown: false }}
    initialRouteName="SignIn"
  >
    <AuthStack.Screen
      name="SignIn"
      component={SignInScreen}
      options={{ title: "uBuyFirst" }}
    />

    <AuthStack.Screen
      name="SignUp"
      component={SignUpScreen}
      options={{ title: "uBuyFirst" }}
    />

    <AuthStack.Screen
      name="ForgotPassword"
      component={ForgotPasswordScreen}
      options={{ title: "uBuyFirst" }}
    />
  </AuthStack.Navigator>
);

const HomeStack = createStackNavigator();
const HomeStackScreen = () => {
  const { firebaseUser } = useFirebaseAuth();
  const { client: apolloClient } = useApolloClientInit(firebaseUser);

  return firebaseUser && apolloClient ? (
    <ApolloProvider client={apolloClient}>
      <HomeStack.Navigator screenOptions={{ headerShown: false }}>
        <HomeStack.Screen
          name="Home"
          component={HomeScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="Searches"
          component={QueryListScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="QueryEdit"
          //@ts-ignore
          component={SearchQueryEditScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="QueryBackup"
          component={QueryBackup}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="About"
          component={AboutScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="ItemDetails"
          component={ItemDetailsScreen}
          options={{ title: "uBuyFirst" }}
        />
        <HomeStack.Screen
          name="BatteryScreen"
          component={BatteryScreen}
          options={{ title: "uBuyFirst" }}
        />
      </HomeStack.Navigator>
    </ApolloProvider>
  ) : (
    <ThemeProvider>
      <BlankScreen />
    </ThemeProvider>
  );
};

const Drawer = createDrawerNavigator();
const DrawerScreen = () => (
  <Drawer.Navigator
    //android freezes in remote debug
    useLegacyImplementation={false}
    drawerContent={(props) => (
      <DrawerItems navigation={props} options={{ title: "uBuyFirst" }} />
    )}
    screenOptions={{ drawerType: "front", headerShown: false }}
  >
    <Drawer.Screen
      name="HomeStack"
      component={HomeStackScreen}
      options={{ title: "uBuyFirst" }}
    />
  </Drawer.Navigator>
);

const RootStack = createStackNavigator();

const RootStackScreen = () => {
  const dispatch = useDispatch();
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user?.uid || "",
  );

  const [initialized, setInitialized] = useState(false);

  const [deviceID, setDeviceID, storageHydrated] = useAsyncStorage(
    "deviceID",
    "",
  );

  useEffect(() => {
    const initializeDevice = async () => {
      if (storageHydrated && !deviceID) {
        const newDeviceID = await getDeviceID();
        setDeviceID(newDeviceID);
        dispatch({ type: "SET_DEVICE_ID", payload: newDeviceID });
      } else if (deviceID) {
        // If deviceID already exists, dispatch it
        dispatch({ type: "SET_DEVICE_ID", payload: deviceID });
      }
      setInitialized(true);
    };

    initializeDevice();
  }, [storageHydrated, deviceID, userID]);

  if (initialized && storageHydrated && userID) {
    return (
      <RootStack.Screen
        name="Drawer"
        component={DrawerScreen}
        options={{ title: "uBuyFirst" }}
      />
    );
  }

  if (initialized && storageHydrated && !userID) {
    return (
      <RootStack.Screen
        name="AuthStack"
        component={AuthStackScreen}
        options={{ animationEnabled: false, title: "uBuyFirst" }}
      />
    );
  }

  return (
    <RootStack.Screen
      name="LoadingScreen"
      component={LoadingScreen}
      options={{ animationEnabled: false, title: "uBuyFirst" }}
    />
  );
};

export const AppNavigator = () => {
  return (
    <NavigationContainer>
      <RootStack.Navigator screenOptions={{ headerShown: false }}>
        {RootStackScreen()}
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

const LoadingScreen = () => {
  const theme = useTheme();
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colors.background,
      }}
    >
      <ProgressBar
        indeterminate
        color={theme.dark ? "black" : theme.colors.primary}
      />
    </View>
  );
};
