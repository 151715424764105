import React, { memo, useEffect } from "react";
import { ProgressBar } from "react-native-paper";

import { useGetItems } from "../Graphql/useItems";
import { RecyclerList } from "./RecyclerList";

const ResultListComponent1 = () => {
  const { data, loading, loadItems } = useGetItems();

  return (
    <>
      {loading && (
        <ProgressBar indeterminate style={{ position: "absolute" }} />
      )}
      <RecyclerList
        data={data}
        loadItems={loadItems}
        totalCount={data?.userItems2?.totalCount}
      />
    </>
  );
};

ResultListComponent1.navigationOptions = {
  title: "Search Results",
};
ResultListComponent1.whyDidYouRender = true;
const ResultListComponent = memo(ResultListComponent1);
export { ResultListComponent };
