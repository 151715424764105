import React from "react";
import { Banner } from "react-native-paper";

import { openNotificationSettings } from "../Messaging/openNotificationSettings";
import { useNotificationPermissions } from "../Messaging/useNotificationPermission";
import { Platform } from "../Platform";
import { openUrl } from "../Tools/openUrl";

export const PermissionBanner = (props: any) => {
  const permissionMessage = "Please, grant notification permissions.";
  const openNotificationSettingsCallback = openNotificationSettings();
  const { permissionGranted, permissionError, requestNotificationPermission } =
    useNotificationPermissions();
  if (permissionGranted) return null;

  const allowNotification = {
    label: "Allow notifications",
    onPress: () => requestNotificationPermission(),
  };
  const openSettings = {
    label: "Notification settings",
    onPress: openNotificationSettingsCallback,
  };
  const allowed = {
    label: "Allowed",
    onPress: () => requestNotificationPermission(),
  };
  const learnMore = {
    label: "Learn more",
    onPress: () => {
      openUrl("https://ubuyfirst.com");
    },
  };

  const actionsOnError =
    Platform.OS === "ios"
      ? [openSettings, allowed]
      : [allowNotification, learnMore];

  return permissionError ? (
    <Banner visible={props.notifyPermissionVisible} actions={actionsOnError}>
      {permissionError}
    </Banner>
  ) : (
    <Banner
      visible={props.notifyPermissionVisible}
      actions={[allowNotification, learnMore]}
    >
      {permissionMessage}
    </Banner>
  );
};
