import React, { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { View } from "react-native";
import { withKeyboardAwareScrollView } from "react-native-dropdown-autocomplete";
import { TextInput } from "react-native-paper";

import { CategoryAutocomplete } from "./CategoryAutocomplete";
import { CategoryChipList } from "./CategoryChipList";

const CategoryEdit = () => {
  return (
    <Field name="categories">
      {({ input }: any) => {
        const [textEditVisible, setTextEditVisible] = useState(false);
        return (
          <View style={{ margin: 6 }}>
            <TextInput
              label="Category"
              value={" "}
              style={{ backgroundColor: "transparent" }}
              render={() => {
                const selectedCats = input.value ? JSON.parse(input.value) : [];
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      //margin: 6,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        paddingTop: selectedCats.length > 0 ? 25 : 20,
                        flexGrow: 1,
                      }}
                    >
                      <CategoryChipList
                        selectedCats={selectedCats}
                        onChange={input.onChange}
                        textEditVisible={textEditVisible}
                        setTextEditVisible={setTextEditVisible}
                      />
                      {/* Lookup many views to avoid  autocomplete shrink
                        and horizontal dropdown on web*/}

                      <View style={{ flexDirection: "row", flexGrow: 1 }}>
                        <View style={{ flexDirection: "column", flexGrow: 1 }}>
                          <FormSpy subscription={{ values: true }}>
                            {({ values }: any) => {
                              if (textEditVisible || selectedCats.length == 0) {
                                return (
                                  <CategoryAutocomplete
                                    siteName={values.siteName}
                                    selectedCategories={selectedCats}
                                    onChange={input.onChange}
                                    setTextEditVisible={setTextEditVisible}
                                  />
                                );
                              } else {
                                return null;
                              }
                            }}
                          </FormSpy>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        );
      }}
    </Field>
  );
};

const CategoryEditIOS = withKeyboardAwareScrollView(CategoryEdit);
export { CategoryEditIOS, CategoryEdit };
