import React from "react";
import { Appbar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";

export const ToggleNotificationButton = (props: any) => {
  const dispatch = useDispatch();
  const notificationsEnabled = useSelector<IRootReducer, boolean>(
    (state) => state.appState.notificationsEnabled,
  );
  const storedPushToken = useSelector<IRootReducer, string>(
    (state) => state.appState.pushToken,
  );

  const toggleNotifications = () => {
    if (storedPushToken) {
      dispatch({ type: "TOGGLE_NOTIFICATIONS" });
    } else {
      props.toggleNotifyPermissionVisible();
    }
  };

  return (
    <Appbar.Action
      //disabled={!storedPushToken}
      icon={notificationsEnabled && storedPushToken ? "bell" : "bell-off"}
      onPress={toggleNotifications}
      size={25}
      color={"white"}
    />
  );
};
