import { useEffect, useRef } from "react";
import { Animated, Easing } from "react-native";

export function useColorFade(fadeTime: number) {
  const fadeColor = useRef(new Animated.Value(0));

  useEffect(() => {
    const epochNow = Math.round(new Date().getTime() / 1000);
    const elapsedSec = epochNow - fadeTime;
    const fadeSecLeft = elapsedSec < 60 ? 60 - elapsedSec : 0;

    fadeColor.current.setValue(300);
    Animated.timing(fadeColor.current, {
      toValue: 0,
      duration: fadeSecLeft * 1000,
      easing: Easing.exp,
      useNativeDriver: false,
    }).start();
  }, [fadeTime]);

  return fadeColor;
}
