import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { Platform } from "../Platform";

export const QuantityIndicator = ({ imageCount }: { imageCount: number }) => {
  const quantityShadowStyle = getQuantityShadowStyle();
  return (
    <View
      style={[
        quantityShadowStyle,
        {
          position: "absolute",
          right: 3,
          bottom: 3,
          justifyContent: "center",
          alignItems: "center",
        },
      ]}
    >
      <Text
        style={{
          color: "white",
          fontSize: Platform.OS === "ios" ? 13 : 11,
        }}
      >
        {imageCount > 1 ? " " + imageCount + " " : ""}
      </Text>
    </View>
  );
};

const getQuantityShadowStyle = () => {
  let quantityShadowStyle = {};
  switch (Platform.OS) {
    case "android":
      quantityShadowStyle = {
        color: "white",
        fontSize: 11,
        opacity: 1,
        borderRadius: 30,
        backgroundColor: "#a8a8a8",
      };
      break;
    case "web":
      quantityShadowStyle = {
        color: "white",
        opacity: 1,
        borderRadius: 15,
        backgroundColor: "#a8a8a8",
      };
      break;
    case "ios":
      quantityShadowStyle = {
        color: "red",
        opacity: 1,
        borderRadius: 7,
        backgroundColor: "#a8a8a8",
      };
      break;

    default:
      break;
  }
  return quantityShadowStyle;
};
