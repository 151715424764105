import React from "react";
import { StyleSheet, View } from "react-native";
import { Button, Surface } from "react-native-paper";

import { useAuth } from "../hooks/useAuth";
import { LightTheme } from "../Theme/LightTheme";
import { SignForm } from "./SignInForm";

const SignInScreen = ({ navigation }: any) => {
  const { signIn, tryAsGuest } = useAuth();

  return (
    <View style={styles.container}>
      <Surface theme={LightTheme} style={{ elevation: 9, width: 300 }}>
        <SignForm onSubmit={signIn} buttonText="Sign in" />

        <Button
          style={styles.button}
          theme={LightTheme}
          mode="text"
          compact
          onPress={() => {
            navigation.navigate("SignUp");
          }}
        >
          Sign up
        </Button>

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Button
            style={styles.button}
            theme={LightTheme}
            compact
            uppercase={false}
            mode="text"
            onPress={tryAsGuest}
          >
            Try as Guest
          </Button>

          <Button
            style={styles.button}
            theme={LightTheme}
            mode="text"
            compact
            uppercase={false}
            onPress={() => {
              navigation.navigate("ForgotPassword");
            }}
          >
            Forgot password
          </Button>
        </View>
      </Surface>
    </View>
  );
};

export { SignInScreen };

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  button: {
    margin: 10,
    alignSelf: "center",
  },
});
