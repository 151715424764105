export interface resultAction {
  payload: { itemID: string; item: IResultItem; limit: number; };
  type: string;
}

export interface IResultItem {
  itemID: string;
  key: string;
  autoPay: string;
  bestOfferEnabled: string;
  bidCount: string;
  conditionDisplayName: string;
  conditionID: string;
  country: string;
  currentPrice: string;
  endTime: string;
  excludeShipToLocation: string;
  feedbackCount: string;
  feedbackPercent: string;
  foundTime: string;
  globalShipping: string;
  hitCount: string;
  id: string;
  imageUrl: string;
  listingStatus: string;
  listingType: string;
  location: string;
  lotSize: string;
  minimumToBid: string;
  paymentMethods: string;
  pictureURL: string;
  price: string;
  primaryCategoryID: string;
  primaryCategoryIDPath: string;
  quantity: string;
  quantitySold: string;
  returnsAccepted: string;
  returnsWithin: string;
  secondaryCategoryID: string;
  secondaryCategoryIDPath: string;
  sellerName: string;
  shippingPrice: string;
  shipToLocations: string;
  site: string;
  startTime: string;
  storeName: string;
  subtitle: string;
  term: string;
  title: string;
  watchCount: string;
  deleted: boolean;
}

export const getShippingPriceStr = (shippingPrice: number) => {
  if (shippingPrice === 0) return "0";

  if (shippingPrice > 0) {
    return `${parseFloat(shippingPrice.toString())
      .toFixed(2)
      .replace(".00", "")}`;
  } else {
    return "?";
  }
};

export const getTotalPriceStr = (price: string, shippingPrice: string) => {
  const priceNum = parseFloat(price);
  const shipPriceNum = parseFloat(shippingPrice);

  if (shipPriceNum >= 0) {
    const totalPrice: number = priceNum + shipPriceNum;
    return (
      "$" + parseFloat(totalPrice.toString()).toFixed(2).replace(".00", "")
    );
  } else {
    return "$" + parseFloat(priceNum.toString()).toFixed(2).replace(".00", "") + "*";
  }
};

export const parseGQLItem = (gqlItem: any): IResultItem => {
  const d = gqlItem.item;
  const item: IResultItem = {
    id: d.itemID,
    key: d.itemID,
    autoPay: d.autoPay,
    bestOfferEnabled: d.bestOfferEnabled,
    bidCount: d.bidCount,
    conditionDisplayName: d.conditionName,
    conditionID: d.conditionID,
    country: d.country,
    currentPrice: d.currentPrice,
    endTime: d.endTime,
    excludeShipToLocation: d.ExcludeLocation,
    feedbackCount: d.feedbackScore,
    feedbackPercent: d.feedbackPercent,
    foundTime: d.foundtime,
    globalShipping: d.globalShipping,
    hitCount: d.hitCount,
    imageUrl: `https://thumbs1.ebaystatic.com/d/l500/pict/${d.itemID}_1.jpg`,
    itemID: d.itemID,
    listingStatus: d.listingStatus,
    listingType: d.listingType,
    location: d.location,
    lotSize: d.lotSize,
    minimumToBid: d.minimumToBid,
    paymentMethods: d.paymentMethods,
    pictureURL: d.picture,
    price: d.price,
    primaryCategoryID: d.primaryCategoryID,
    primaryCategoryIDPath: d.primaryCategoryPath,
    quantity: d.quantity,
    quantitySold: d.quantitySold,
    returnsAccepted: d.returnsAccepted,
    returnsWithin: d.returnsWithin,
    secondaryCategoryID: d.secondaryCategoryID,
    secondaryCategoryIDPath: d.secondaryCategoryPath,
    sellerName: d.seller,
    shippingPrice: gqlItem.shipping_price,
    shipToLocations: d.shipTo,
    site: d.site,
    startTime: d.startTime,
    storeName: d.storeName,
    subtitle: d.subtitle,
    term: d.term,
    title: d.title,
    watchCount: d.watchCount,
    deleted: gqlItem.deleted,
  };
  return item;
};

export const parsePushItem = (d: any): IResultItem => {
  const item: IResultItem = {
    id: d.id,
    key: d.id,
    autoPay: d.a,
    bestOfferEnabled: d.bo,
    bidCount: d.bc,
    conditionDisplayName: d.cd,
    conditionID: d.ci,
    country: d.c,
    currentPrice: d.cp,
    endTime: d.et,
    excludeShipToLocation: d.es,
    feedbackCount: d.sf,
    feedbackPercent: d.sp,
    foundTime: d.ft,
    globalShipping: d.gs,
    hitCount: d.h,
    imageUrl: `https://thumbs1.ebaystatic.com/d/l500/pict/${d.id}_1.jpg`,
    itemID: d.id,
    listingStatus: d.ls,
    listingType: d.lt,
    location: d.l,
    lotSize: d.lz,
    minimumToBid: d.mt,
    paymentMethods: d.pm,
    pictureURL: d.pic,
    price: d.p,
    primaryCategoryID: d.pc,
    primaryCategoryIDPath: d.pc,
    quantity: d.q,
    quantitySold: d.qs,
    //returnPolicy: d.rp,
    returnsAccepted: d.ra,
    returnsWithin: d.rw,
    secondaryCategoryID: d.sc,
    secondaryCategoryIDPath: d.sc,
    sellerName: d.s,
    shippingPrice: d.shp,
    shipToLocations: d.stl,
    site: d.sit,
    startTime: d.st,
    storeName: d.sn,
    subtitle: d.su,
    term: d.te,
    title: d.ti,
    watchCount: d.wc,
    deleted: false,
  };
  return item;
};
