import React from "react";
import { Appbar } from "react-native-paper";

import { useNavigation, NavigationProp } from "@react-navigation/native";

import { useTerms } from "../Graphql/useTerms";
import { DefaultQuery } from "../interfaces/Query";

export const NewSearchButton = () => {
  const navigation = useNavigation<NavigationProp<any>>();
  const { getTerms, showSearchesLimited } = useTerms();
  const terms = getTerms("cache-and-network");

  const createSearch = () => {
    if (terms)
      if (terms.length >= 30) {
        showSearchesLimited();
      } else {
        navigation.navigate({
          name: "QueryEdit",
          params: { query: DefaultQuery },
        });
      }
  };

  return (
    <Appbar.Action
      icon={"plus"}
      onPress={createSearch}
      size={30}
      color={"white"}
      disabled={terms && terms.length >= 30}
    />
  );
};
