import React, { useState } from "react";
import { Text } from "react-native-paper";

import { useInterval } from "../hooks/useInterval";

export const Stopwatch = ({ foundTime }: any) => {
  const found2Elapsed = () => {
    const epochNow = Math.round(new Date().getTime() / 1000);
    return secondsToHms(epochNow - parseInt(foundTime));
  };
  const [elapsedText, setElapsedText] = useState(found2Elapsed());

  const elapsedHandler = () => {
    setElapsedText(found2Elapsed());
  };

  useInterval(elapsedHandler, 1000);

  return (
    <>
      <Text
        style={{
          opacity: 0.5,
          fontSize: 12,
          paddingTop: 5,
          position: "absolute",
          bottom: 5,
          right: 10,
        }}
      >
        {elapsedText}
      </Text>
    </>
  );
};

function secondsToHms(d: number) {
  d = Number(d);
  let seconds = d;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  let dayDisplay = "";
  if (days > 0) dayDisplay = days + "d";

  let hourDisplay = "";
  if (!dayDisplay && hours > 0) {
    hourDisplay = hours + "h";
  }

  let minDisplay = "";
  if (!dayDisplay && !hourDisplay && minutes > 0) {
    minDisplay = minutes + "m";
  }

  let secDisplay = "";
  if (!dayDisplay && !hourDisplay && !minDisplay && seconds > 0) {
    secDisplay = seconds + "s";
  }
  return dayDisplay + hourDisplay + minDisplay + secDisplay;
}
