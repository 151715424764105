import React, { memo } from "react";
import { Animated, View } from "react-native";
import { Surface } from "react-native-paper";
import { useSelector } from "react-redux";

import { NavigationProp, useNavigation } from "@react-navigation/native";

import { useDeleteItems } from "../Graphql/useDeleteItems";
import Hoverable from "../hooks/Hoverable";
import { INode } from "../interfaces/Node";
import { IRootReducer } from "../reducers/allReducers";
import { ResultCard } from "./ResultCard";
import { ResultRow } from "./ResultRow";
import { useColorFade } from "./useColorFade";

const ResultItem1 = ({ node }: { node: INode }) => {
  const viewType = useSelector<IRootReducer, string>(
    (state) => state.appState.viewType,
  );
  const navigation = useNavigation<NavigationProp<any>>();
  const { deleteItem } = useDeleteItems();

  const imageCount = node.item?.picture?.split(";").length;
  const thumbnailClick = () => {
    const imgArray: { url: string }[] = node.item.picture
      .split(";")
      .map((img: string) => ({ url: img.replace(/\$_[0-9]+/, "$_10") }));
    navigation.navigate({
      name: "ItemDetails",
      params: { node: node, images: imgArray },
    });
  };

  const bgColor = useColorFade(node.item.foundtime);

  return node.deleted ? null : (
    <Hoverable>
      {(isHovered: boolean) => (
        <View style={{ flex: 1 }}>
          <Surface
            elevation={isHovered ? 5 : 1}
            style={{
              marginHorizontal: 5,
              marginVertical: 5,
              borderRadius: 4,
              flex: 1,
              flexBasis: "95%",
            }}
          >
            <Animated.View
              style={{
                flex: 1,
                backgroundColor: bgColor.current.interpolate({
                  inputRange: [0, 300],
                  outputRange: ["rgba(0, 0, 0, 0)", "rgba(0, 255, 0, 0.15)"],
                }),
              }}
            >
              {viewType === "column" ? (
                <ResultCard
                  thumbnailClick={thumbnailClick}
                  node={node}
                  imageCount={imageCount}
                  deleteItem={deleteItem}
                  isHovered={isHovered}
                />
              ) : (
                <ResultRow
                  thumbnailClick={thumbnailClick}
                  node={node}
                  imageCount={imageCount}
                  deleteItem={deleteItem}
                  isHovered={isHovered}
                />
              )}
            </Animated.View>
          </Surface>
        </View>
      )}
    </Hoverable>
  );
};

const ResultItem = memo(ResultItem1, memoPropsAreEqual);

function memoPropsAreEqual(prev: any, next: any) {
  return prev.node.id === next.node.id;
}

export { ResultItem };
