import { persistCache } from "apollo3-cache-persist";
import { createClient } from "graphql-ws";
import { useEffect, useState } from "react";

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { FirebaseAuthTypes } from "@react-native-firebase/auth";

import { Platform } from "../Platform";
import { AsyncStorage } from "../Tools/Storage";

//https://old.reddit.com/r/graphql/comments/n5jps4/apollo_dynamically_change_authorization_token_link/
export const getApolloWSHttpLink = async (firebaseUser: FirebaseAuthTypes.User | undefined) => {
  const wsUrl = "wss://data.ubuyfirst.net/graphql";
  const httpUrl = "https://data.ubuyfirst.net/graphql";
  //const wsUrl = "wss://data.ubuyfirst.net/graphql_stage";
  //const httpUrl = "https://data.ubuyfirst.net/graphql_stage";
  //const wsUrl = "ws://127.0.0.1:5002/";
  //const httpUrl = "http://127.0.0.1:5002/";
  //const wsUrl = "wss://ubuyfirstapi.dynu.net/graphql";
  //const httpUrl = "https://ubuyfirstapi.dynu.net/graphql";


  const querySplitter = ({ query }: any) => {
    const def = getMainDefinition(query);
    const isSubscription = def.kind === "OperationDefinition" && def.operation === "subscription";
    return isSubscription;
  };

  const token = await firebaseUser?.getIdToken();
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUrl,
      lazy: true,
      connectionParams: {
        authorization: token ? `Bearer ${token}` : "",
      },
      shouldRetry: () => true,
      retryAttempts: 9999,
      retryWait: async (count) => {
        await new Promise((resolve) => setTimeout(resolve, Math.min(count, 300) * 1000));
      },
    }
    )
  );
  const authLink = setContext(async () => {
    const token = await firebaseUser?.getIdToken();
    return {
      headers: { authorization: token ? `Bearer ${token}` : "" },
    };
  });

  const httpLink = new HttpLink({ uri: httpUrl });

  const HttpWsLink = split(
    querySplitter,
    wsLink,
    authLink.concat(httpLink),
  );

  return HttpWsLink;
};

const useApolloClientInit = (firebaseUser: FirebaseAuthTypes.User | undefined) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      if (!firebaseUser)
        return;
      const cache = new InMemoryCache();
      const storage = Platform.OS == "web" ? window.localStorage : AsyncStorage;
      await persistCache({
        cache,
        storage: storage,
      });

      const apolloLink = await getApolloWSHttpLink(firebaseUser);
      const apolloClient = new ApolloClient({
        link: apolloLink,
        cache
      });

      setClient(apolloClient);
    }

    init().catch(console.error);
  }, [firebaseUser]);
  return { client };
};

export { useApolloClientInit };
