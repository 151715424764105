import React from "react";
import { View } from "react-native";
import { useTheme } from "react-native-paper";

export const BlankScreen = () => {
  const theme = useTheme();

  return (
    <View style={{ backgroundColor: theme.colors.background, flex: 1 }}></View>
  );
};
