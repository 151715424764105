import { gql } from "@apollo/client";

export const termsQuery = gql`
  query termsQuery($uid: String!, $first: Int!) {
    terms(
      orderBy: { createdAt: desc }
      first: $first
      where: { uid: { equals: $uid } }
    ) {
      id
      uid
      enabled
      action
      alias
      availableTo
      categories
      categoriesName
      condition
      locatedIn
      keyword
      listingType
      minFeedbackCount
      minFeedbackPercent
      priceMax
      priceMin
      searchInDescription
      sellerList
      sellerFilter
      site
      zip
      createdAt
      updatedAt
    }
  }
`;

export const addTermMutation = gql`
  mutation  createOneTerm($data: TermCreateInput!) {
    createOneTerm(data: $data) {
      id
      uid
      enabled
      action
      alias
      availableTo
      categories
      categoriesName
      condition
      locatedIn
      keyword
      listingType
      minFeedbackCount
      minFeedbackPercent
      priceMax
      priceMin
      searchInDescription
      sellerList
      sellerFilter
      site
      zip
      createdAt
      updatedAt
    }
  }
`;

export const updateTermMutation = gql`
  mutation updateOneTerm($data: TermUpdateInput!, $where: TermWhereUniqueInput!) {
    updateOneTerm(data: $data, where: $where) {
      id
      uid
      enabled
      action
      alias
      availableTo
      categories
      categoriesName
      condition
      locatedIn
      keyword
      listingType
      minFeedbackCount
      minFeedbackPercent
      priceMax
      priceMin
      searchInDescription
      sellerList
      sellerFilter
      site
      zip
      createdAt
      updatedAt
    }
  }
`;

export const deleteTermMutation = gql`
  mutation deleteTerm($data: TermWhereUniqueInput!) {
    deleteOneTerm(where: $data) {
      id
    }
  }
`;

export const itemsQuery = gql`
  query ItemsQuery(
    $uid: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $skip: Int
  ) {
    userItems2(
      orderBy: { createdAt: desc }
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        AND: [{ deleted: { not: { equals: true } } }, { uid: { equals: $uid } }]
      }
      skip: $skip
    ) {
      edges {
        node {
          id: itemID
          term
          shipping_price
          site
          deleted
          createdAt
          item {
            id
            autoPay
            bidCount
            bestOfferEnabled
            country
            conditionID
            conditionName
            currentPrice
            currencyID
            excludeLocation
            endTime
            foundtime
            globalShipping
            hitCount
            itemID
            location
            listingStatus
            listingType
            lotSize
            minimumToBid
            paymentMethods
            price
            picture
            primaryCategoryID
            primaryCategoryPath
            returnsAccepted
            returnsWithin
            quantity
            quantitySold
            seller
            secondaryCategoryID
            secondaryCategoryPath
            feedbackScore
            site
            feedbackPercent
            startTime
            shipTo
            subtitle
            storeName
            title
            watchCount
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const itemsSubscription = gql`
  subscription MySubscription($uid: String!) {
   newItem(where: {uid: {equals: $uid}}) {
      id: itemID
      createdAt
      site
      deleted
      term
      shipping_price
      item {
        id
        autoPay
        bidCount
        bestOfferEnabled
        country
        conditionID
        conditionName
        currentPrice
        currencyID
        excludeLocation
        endTime
        foundtime
        globalShipping
        hitCount
        itemID
        location
        listingStatus
        listingType
        lotSize
        minimumToBid
        paymentMethods
        price
        picture
        primaryCategoryID
        primaryCategoryPath
        returnsAccepted
        returnsWithin
        quantity
        quantitySold
        seller
        secondaryCategoryID
        secondaryCategoryPath
        feedbackScore
        site
        feedbackPercent
        startTime
        shipTo
        subtitle
        storeName
        title
        watchCount
      }
    }
  }
`;

// export const deleteItemMutation2 = gql`
//   mutation deleteItem($uid_itemID: String!) {
//     deleteOneuser_item(where: $data) {
//       uid_itemID
//     }
//   }
// `;
// export const deleteItemMutation = gql`
//   mutation deleteItem($data: user_itemWhereUniqueInput!) {
//     deleteOneuser_item(where: $data) {
//       uid_itemID
//     }
//   }
// `;

export const markDeletedItemMutation = gql`
  mutation markDeletedItemMutation($uid_itemID: String!) {
    updateOneuser_item(
      data: { deleted: { set: true }}
      where: { uid_itemID: $uid_itemID }
    ) {
      id: itemID
      deleted
    }
  }
`;

export const deleteAllItemsMutation = gql`
  mutation clearItems($data: user_itemWhereInput!) {
    deleteManyuser_item(where: $data) {
      count
    }
  }
`;
