import React from "react";
import { StyleSheet, View } from "react-native";
import { Button, Surface } from "react-native-paper";

import { useAuth } from "../hooks/useAuth";
import { LightTheme } from "../Theme/LightTheme";
import { SignForm } from "./SignInForm";

const SignUpScreen = (props: any) => {
  const { signUp } = useAuth();

  return (
    <View style={styles.container}>
      <Surface theme={LightTheme} style={{ elevation: 9, width: 300 }}>
        <SignForm onSubmit={signUp} buttonText="Sign up" />

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <Button
            style={styles.button}
            theme={LightTheme}
            compact
            mode="text"
            onPress={() => props.navigation.navigate("SignIn")}
          >
            Go back
          </Button>
        </View>
      </Surface>
    </View>
  );
};

export { SignUpScreen };

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: 5,
    alignSelf: "center",
  },
});
