export interface IHash {
  [key: string]: string;
}

export const GetConditionName = (conditionID: string): string => {
  const conditionStr = ConditionDict[conditionID];
  if (!conditionStr) return "Unspecified";
  return conditionStr;
};

export const GetConditionID = (conditionName: string): string => {
  const entries = Object.entries(ConditionDict);

  for (let index = 0; index < entries.length; index++) {
    if (entries[index][1] === conditionName) {
      return entries[index][0];
    }
  }
  return "";
};

export const ConditionDict: IHash = {
  "1000": "New",
  "1500": "New other",
  "1750": "New with defects",
  "2000": "Manufacturer refurbished",
  "2500": "Seller refurbished",
  "2750": "Like new",
  "3000": "Used",
  "4000": "Very good",
  "5000": "Good",
  "6000": "Acceptable",
  "7000": "For parts or not working",
  "Unspecified": "Unspecified",
};
