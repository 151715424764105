import React from "react";
import { ImageBackground, Pressable, StyleSheet, View } from "react-native";
import { Divider, Text } from "react-native-paper";
import { useSelector } from "react-redux";

import { OpenAffiliateLink } from "../Geo/Affiliate";
import { getTotalPriceStr } from "../interfaces/ResultItem";
import { Platform } from "../Platform";
import { IRootReducer } from "../reducers/allReducers";
import { DeleteItemButton } from "./DeleteItemButton";
import { QuantityIndicator } from "./QuantityIndicator";
import { Stopwatch } from "./Stopwatch";

export const ResultRow = ({
  isHovered,
  thumbnailClick,
  node,
  imageCount,
  deleteItem,
}: {
  isHovered: any;
  thumbnailClick: () => void;
  node: any;
  imageCount: number;
  deleteItem: (arg0: any) => void;
}) => {
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );

  const imgArray: string[] = node.item.picture
    .split(";")
    .map((img: string) => img.replace(/\$_[0-9]+/, "$_10"));
  var thumbNail = imgArray ? imgArray[0] : "";

  return (
    <View style={styles.rowFront}>
      {/* Thumbnail */}
      <Pressable
        style={{
          marginHorizontal: 5,
          justifyContent: "center",
        }}
        onPress={thumbnailClick}
      >
        <ImageBackground
          source={{ uri: thumbNail }}
          style={styles.thumbStyle}
          imageStyle={{ borderRadius: 4 }}
        >
          <QuantityIndicator imageCount={imageCount} />
        </ImageBackground>
      </Pressable>

      {/* Item info */}
      <Pressable
        style={styles.cellInfo}
        onPress={() => {
          OpenAffiliateLink(userID, node.item.itemID, node.site);
        }}
      >
        <View style={{ flex: 1, flexDirection: "column", padding: 1 }}>
          {/* Title */}
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text style={{ fontWeight: "bold" }}>{node.item.title}</Text>
          </View>

          {/* Item detail info */}
          <View style={{ flexDirection: "row" }}>
            <View style={{ flexGrow: 1 }}>
              <Divider style={{ margin: 2 }} />
              <Text style={{ fontWeight: "bold", color: "#1db954" }}>
                {getTotalPriceStr(node.item.price, node.shipping_price)}
              </Text>
              <Text>{node.item.conditionName}</Text>
              <Text style={{ alignSelf: "flex-start" }}>
                {node.item.quantity}
              </Text>
            </View>
          </View>
        </View>
        <Stopwatch foundTime={node.item.foundtime} />
      </Pressable>
      {(isHovered || Platform.OS !== "web") && (
        <DeleteItemButton itemID={node.item.itemID} deleteItem={deleteItem} />
      )}
    </View>
  );
};

export const styles = StyleSheet.create({
  rowFront: {
    flex: 1,
    flexDirection: "row",
    margin: 2,
  },
  cellInfo: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 5,
    paddingBottom: 5,
  },
  thumbStyle: {
    width: 80,
    height: 80,
  },
});
