import React, { useEffect } from "react";
import { Appbar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";

import { FS } from "../constants";
import { firebaseApp } from "../firebase/firebase_config";
import { useFirebase } from "../hooks/useFirebase";
import { IRootReducer } from "../reducers/allReducers";

export const ToggleSearchButton = () => {
  const dispatch = useDispatch();
  const deviceID = useSelector<IRootReducer, string>(
    (state) => state.appState.deviceID,
  );
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );
  const searchStarted = useSelector<IRootReducer, boolean>(
    (state) => state.appState.searchStarted,
  );

  useEffect(() => {
    const startedPath = firebaseApp
      .database()
      .ref(`${FS.users}/${userID}/${FS.searchStarted}`);
    const searchStartedSubscription = startedPath.on(
      "value",
      (snapshot: any) => {
        if (snapshot.val() === true) {
          dispatch({ type: "START_SEARCH" });
        }
        if (snapshot.val() === false) {
          dispatch({ type: "STOP_SEARCH" });
        }
      },
    );
    return () => {
      startedPath.off("value", searchStartedSubscription);
    };
  }, [userID, deviceID, searchStarted]);

  const { toggleSearchStarted } = useFirebase();
  return (
    <Appbar.Action
      icon={searchStarted ? "pause" : "play"}
      onPress={toggleSearchStarted}
      size={40}
      color={"white"}
    />
  );
};
