import React from "react";
import { ViewStyle } from "react-native";
import { MD3Theme, TextInput } from "react-native-paper";

import { PickerComponent } from "./PickerComponent";

export const PickerCustom = (props: {
  theme: MD3Theme;
  label: string;
  value: string;
  onChange: any;
  items: string[];
  style?: ViewStyle;
}) => {
  return (
    <TextInput
      style={{
        flexGrow: 1,
        margin: 6,
      }}
      label={props.label}
      value={" "}
      mode="outlined"
      render={() => <PickerComponent {...props} />}
    />
  );
};
