import React from "react";
import { View } from "react-native";

import { ThemeProvider } from "./contexts/ThemeContext";
import { useAppKeepAwake } from "./hooks/useAppKeepAwake";
import usePresence from "./hooks/usePresence";
import { AppNavigator } from "./Navigation/AppNavigator";
import { Visibility } from "./Tools/Visibility";

//TODO:
//Pagination for terms
const MainComponent = () => {
  console.log("----------------APP START-----------------");

  //usePushTokenListener();
  usePresence();
  useAppKeepAwake();

  return (
    <ThemeProvider>
      <View style={{ flex: 1 }}>
        <AppNavigator />
        <Visibility />
      </View>
    </ThemeProvider>
  );
};

MainComponent.whyDidYouRender = true;
export { MainComponent as App1 };
