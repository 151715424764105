import React from "react";
import { IconButton, Text } from "react-native-paper";

import { Platform } from "../../Platform";

const DropdownIcon = () => {
  return Platform.OS == "web" ? (
    <Text
      style={{
        position: "absolute",
        right: 0,
        bottom: 0,
        margin: 16,
        marginRight: 12,
        opacity: 1,
        fontSize: 19,
      }}
    >
      ▾
    </Text>
  ) : (
    <IconButton
      icon={"menu-down"}
      style={{
        position: "absolute",
        right: 0,
        bottom: 0,
        margin: 6,
        opacity: 0.5,
      }}
    />
  );
};

export { DropdownIcon };
