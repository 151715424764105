import { memo, useEffect } from "react";

import { useAppVisibility } from "../hooks/useAppVisibility";
import { useBrightness } from "../hooks/useBrightness";
import { useFirebase } from "../hooks/useFirebase";
import { cancelAllNotifications } from "../Messaging/NotificationService";
import { Platform } from "../Platform";

const Visibility1 = () => {
  const { applyBrightnessOnAppStart, restoreSystemBrightnessOnShutdown } =
    useBrightness();

  const isVisible = useAppVisibility();
  const { sendVisibilityState } = useFirebase();
  useEffect(() => {
    if (isVisible) {
      if (Platform.OS !== "web") {
        cancelAllNotifications()
          .then(() => console.log("Notifications cancelled"))
          .catch();
      }
    }
    if (Platform.OS === "ios")
      if (isVisible) {
        applyBrightnessOnAppStart();
      } else {
        restoreSystemBrightnessOnShutdown();
      }
  }, [isVisible]);

  useEffect(() => {
    sendVisibilityState(isVisible ? "v" : "h");
  }, [isVisible]);

  return null;
};

const Visibility = memo(Visibility1);
export { Visibility };
