import React, { useState } from "react";
import { View } from "react-native";
import { Divider, IconButton, Menu } from "react-native-paper";

import MenuWithTimeout from "../components/MenuWithTimeout";

export const QueryMenu = (props: any) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <View style={{}}>
      <MenuWithTimeout
        visible={menuVisible}
        onDismiss={() => setMenuVisible(false)}
        anchor={
          <IconButton
            icon="dots-vertical"
            size={21}
            onPress={() => setMenuVisible(!menuVisible)}
          />
        }
      >
        <Menu.Item
          title={props.queryEnabled ? "Disable" : "Enable"}
          leadingIcon={
            props.queryEnabled ? "toggle-switch" : "toggle-switch-off-outline"
          }
          onPress={() => {
            props.onToggle();
            setMenuVisible(false);
          }}
        />
        <Menu.Item
          title="Edit"
          leadingIcon="pencil"
          onPress={() => {
            props.onEdit();
            setMenuVisible(false);
          }}
        />
        <Menu.Item
          title="Make a copy"
          leadingIcon="content-copy"
          onPress={() => {
            props.onCopy();
            setMenuVisible(false);
          }}
        />
        <Divider />
        <Menu.Item
          title="Delete"
          leadingIcon="delete"
          onPress={() => {
            props.onDelete();
            setMenuVisible(false);
          }}
        />
      </MenuWithTimeout>
    </View>
  );
};
