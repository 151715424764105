import { throttle } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";
import { playPlatformSound } from "../Tools/soundPlay";
import { AsyncStorage } from "../Tools/Storage";

export const useSound = () => {
  const soundEnabled = useSelector<IRootReducer, boolean>(
    (state) => state.appState.soundEnabled,
  );
  const soundFile = useSelector<IRootReducer, string>(
    (state) => state.appState.soundFile,
  );

  useEffect(() => {
    AsyncStorage.setItem(
      "notify",
      JSON.stringify({
        soundEnabled: soundEnabled,
        name: "notification",
      }),
    )
      .then()
      .catch();
  }, [soundEnabled]);

  const playSound = useCallback(() => {
    if (soundEnabled) {
      playPlatformSound(soundFile);
    }
  }, [soundEnabled, soundFile]);

  const debouncedSound = useRef(throttle(() => playSound(), 1000));
  useEffect(() => {
    debouncedSound.current = throttle(() => playSound(), 1000);
  }, [playSound]);

  return { debouncedSound };
};
