import { useEffect, useState } from "react";
import { AppState } from "react-native";
import { useSelector } from "react-redux";

import { FS } from "../constants";
import { firebaseApp, ServerValue } from "../firebase/firebase_config";
import { Platform } from "../Platform";
import { IRootReducer } from "../reducers/allReducers";

const usePresence = () => {
  const deviceID = useSelector<IRootReducer, string>(
    (state) => state.appState.deviceID,
  );
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );
  const storedPushToken = useSelector<IRootReducer, string>(
    (state) => state.appState.pushToken,
  );
  const notificationsEnabled = useSelector<IRootReducer, boolean>(
    (state) => state.appState.notificationsEnabled,
  );

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userID || !deviceID) {
      console.log("No Auth:", userID, " Device id:", deviceID);
      return;
    }
    const deviceStatePath = `${FS.users}/${userID}/${FS.device}/${deviceID}`;

    const updateDeviceState = async () => {
      const platform = "safari" in window ? "safari" : Platform.OS;
      const devicePath = firebaseApp.database().ref(deviceStatePath);
      const deviceState = {
        [`${FS.pushToken}`]: storedPushToken ?? "None",
        [`${FS.platform}`]: platform,
        [`${FS.notificationsEnabled}`]: notificationsEnabled ?? false,
        [`${FS.timestamp}`]: ServerValue.TIMESTAMP,
      };
      await devicePath.update(deviceState);
    };

    updateDeviceState().then().catch(error => setError(error));

    return function cleanup() {
      setError(null);
    };
  }, [userID, deviceID, storedPushToken, notificationsEnabled]);


  useEffect(() => {
    if (!userID || !deviceID) {
      console.log("No Auth:", userID, " Device id:", deviceID);
      return;
    }

    const deviceStatePath = `${FS.users}/${userID}/${FS.device}/${deviceID}`;
    const connStatePath = `o/${userID}___${deviceID}`;
    const onConnectionStateChanged = async () => {
      await firebaseApp.database().goOnline();

      const onOnline = async (snapshot: any): Promise<void> => {

        if (!snapshot.val()) return;
        const updateDeviceConnectionState = async (path: string) => {
          const visibilityState = AppState.currentState === "active" ? "v" : "h";
          const visibilityPath = firebaseApp.database().ref(path);
          const connected = { t: ServerValue.TIMESTAMP, v: visibilityState };
          const disconnected = { d: ServerValue.TIMESTAMP, v: "d" };
          await visibilityPath
            .onDisconnect()
            .update(disconnected)
            .then(async () => {
              if (visibilityState != "v")
                await visibilityPath.update(connected);
            });
        };
        updateDeviceConnectionState(deviceStatePath).then().catch();
        const connStateRef = firebaseApp.database().ref(connStatePath);
        await connStateRef
          .onDisconnect()
          .remove()
          .then(async () => {
            await connStateRef.set({
              on: ServerValue.TIMESTAMP,
            });
          });
      };


      firebaseApp.database().ref(".info/connected").on("value", onOnline);
    };
    onConnectionStateChanged().then().catch(error => setError(error));
    return () => {
      setError(null);
    };
  }, [userID, deviceID]);

  return error ? error : null;
};

export default usePresence;
