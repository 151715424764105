import React, { memo } from "react";
import { ImageBackground, Platform, Pressable, View } from "react-native";
import { Divider, Text } from "react-native-paper";
import { useSelector } from "react-redux";

import { OpenAffiliateLink } from "../Geo/Affiliate";
import { FastImage } from "../Images/FastImage";
import { INode } from "../interfaces/Node";
import { getTotalPriceStr } from "../interfaces/ResultItem";
import { IRootReducer } from "../reducers/allReducers";
import { DeleteItemButton } from "./DeleteItemButton";
import { QuantityIndicator } from "./QuantityIndicator";
import { Stopwatch } from "./Stopwatch";

const Thumbnail1 = ({
  thumbnailClick,
  node,
  thumbNail,
  imageCount,
}: {
  thumbnailClick: any;
  node: INode;
  thumbNail: string;
  imageCount: number;
}) => (
  <Pressable
    onPress={thumbnailClick}
    //@ts-ignore
    accessibilityComponentType="button"
    accessibilityRole="button"
    style={{ flex: 1, padding: 5 }}
  >
    <FastImage
      source={{ uri: thumbNail }}
      resizeMode={"contain"}
      style={{ flex: 1, borderRadius: 10, zIndex: -1 }}
    >
      {/* <QuantityIndicator imageCount={imageCount} />{" "} */}
    </FastImage>
    <View />
  </Pressable>
);

const Thumbnail = memo(Thumbnail1);

const Title = (props: { title: string }) => (
  <>
    <Text style={{ fontWeight: "bold" }}>{props.title}</Text>
  </>
);

const ItemDetails = ({ node }: { node: INode }) => (
  <View>
    <Divider style={{ margin: 2 }} />
    <View>
      <Text style={{ fontWeight: "bold", color: "#1db954" }}>
        {getTotalPriceStr(
          node.item.price.toString(),
          node.shipping_price.toString(),
        )}
      </Text>
      <Text>{node.item.conditionName}</Text>
      <Text style={{ alignSelf: "flex-start" }}>{node.item.quantity}</Text>
      <Stopwatch foundTime={node.item.foundtime} />
    </View>
  </View>
);

const ResultCard1 = (props: {
  isHovered: any;
  deleteItem: (arg0: any) => void;
  node: INode;
  thumbnailClick: any;
  imageCount: number;
}) => {
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );

  const imgArray: string[] = props.node.item.picture
    .split(";")
    .map((img: string) => img.replace(/\$_[0-9]+/, "$_10"));
  var thumbNail = imgArray ? imgArray[0] : "";

  return (
    <View style={{ flexDirection: "column", flex: 1 }}>
      {(props.isHovered || Platform.OS !== "web") && (
        <DeleteItemButton
          deleteItem={props.deleteItem}
          itemID={props.node.item.itemID}
        />
      )}

      {/* Item info */}
      <Pressable
        style={{ flex: 1 }}
        onPress={() => {
          OpenAffiliateLink(userID, props.node.item.itemID, props.node.site);
        }}
        //@ts-ignore
        accessibilityComponentType="button"
        accessibilityRole="button"
      >
        <View style={{ flex: 1 }}>
          <Thumbnail
            thumbnailClick={props.thumbnailClick}
            node={props.node}
            thumbNail={thumbNail}
            imageCount={props.imageCount}
          />

          <View
            style={{
              justifyContent: "flex-end",
              flexDirection: "column",
              margin: 10,
            }}
          >
            <Title title={props.node.item.title} />
            <ItemDetails node={props.node} />
          </View>
        </View>
      </Pressable>
    </View>
  );
};

const ResultCard = ResultCard1;
export { ResultCard };
