import React, { useState } from "react";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";
import { Button, Subheading, Surface, Text } from "react-native-paper";
import { useSelector } from "react-redux";

import { OpenAffiliateLink } from "../Geo/Affiliate";
import { INode } from "../interfaces/Node";
import {
  getShippingPriceStr,
  getTotalPriceStr,
} from "../interfaces/ResultItem";
import { IRootReducer } from "../reducers/allReducers";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 2,
  },
  caption: { flex: 1, marginRight: 5, opacity: 0.5 },
  value: { flex: 3 },
});

const Row = ({ n, v, vStyle }: any) => {
  return (
    <View style={styles.row}>
      <Text style={styles.caption}>{n}</Text>
      <Text style={[styles.value, vStyle]}>{v}</Text>
    </View>
  );
};

export const ItemProperties = ({ node }: { node: INode }) => {
  const [toggleState, setToggleState] = useState(false);
  const userID = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );

  if (!node) return null;
  const item = node.item;
  return (
    <View>
      <Surface style={{ margin: 5, padding: 10, borderRadius: 5 }}>
        <TouchableOpacity
          style={{ flexDirection: "row", alignItems: "center" }}
          onPress={() => {
            OpenAffiliateLink(userID, item.itemID, node.site);
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold" }}>{item.title}</Text>
        </TouchableOpacity>
      </Surface>

      <Surface
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginHorizontal: 5,
          marginBottom: 5,
          borderRadius: 5,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
          }}
          onPress={() => {
            OpenAffiliateLink(userID, item.itemID, node.site);
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              color: "green",
              fontSize: 20,
              borderRadius: 5,
            }}
          >
            {getTotalPriceStr(
              item.price.toString(),
              node.shipping_price.toString(),
            )}
          </Text>
          {/* <View style={{ alignItems: "flex-end" }}>
            <Text
              style={{
                fontSize: 12,
                marginLeft: 10,
              }}
            >
              {"$" +
                parseFloat(item.price.toString()).toFixed(2).replace(".00", "")}
            </Text>
            <Text
              style={{
                fontSize: 12,
                marginLeft: 10,
                opacity: 0.5,
              }}
            >
              {"$" + "" + getShippingPriceStr(node.shipping_price) + ""}
            </Text>
          </View> */}
          <Button
            mode={"contained"}
            style={{
              margin: 5,
              marginLeft: 50,
              paddingHorizontal: 30,
            }}
            onPress={() => {
              OpenAffiliateLink(userID, item.itemID, node.site);
            }}
          >
            Buy
          </Button>
        </TouchableOpacity>
      </Surface>

      <Surface
        style={{
          borderRadius: 5,
          marginHorizontal: 5,
          marginBottom: 5,
          padding: 10,
        }}
        // onPress={() => {
        //   setToggleState(!toggleState);
        // }}
      >
        {item.subtitle ? <Subheading>{item.subtitle}</Subheading> : null}
        {/* <Row
              console.log("ItemProperties ~ item.price", item.price)
              console.log("ItemProperties ~ item.price", item.price)
          n="Total"
          v={getTotalPriceStr(item.price, node.shipping_price)}
          vStyle={{ fontWeight: "bold", color: "green", fontSize: 16 }}
        />
        <Row n="Price" v={`$${item.price.toFixed(2)}`} />
        <Row n="Shipping" v={getShippingPriceStr(node.shipping_price)} /> */}

        <Row n="Condition" v={item.conditionName} />
        <Row n="Quantity" v={item.quantity.toString()} />
        <Row
          n="Seller"
          v={`${item.seller}   (${item.feedbackScore}) ${item.feedbackPercent}%`}
        />
        <Row n="Country" v={`${item.location}, ${item.country}`} />
        <Row
          n="Returns"
          v={`${item.returnsAccepted
            .replace("Returns Accepted", "Accepted")
            .replace("ReturnsNotAccepted", "Not Accepted")} ${
            item.returnsWithin
          }`}
        />
      </Surface>
      {toggleState && (
        <Pressable
          style={{
            paddingVertical: 10,
            paddingBottom: 10,
            padding: 10,
          }}
        >
          <Row n="Commit to buy" v={item.autoPay ? "No" : "Yes"} />
          <Row n="Payment" v={item.paymentMethods} />
          <Row n="Best offer" v={item.bestOfferEnabled ? "Yes" : "No"} />
          <Row n="Bids" v={item.bidCount.toString()} />
          <Row n="Hits" v={item.hitCount.toString()} />
          <Row n="Global Shipping" v={item.globalShipping} />
          <Row n="Listing Type" v={item.listingType} />
          <Row n="Lots" v={item.lotSize.toString()} />

          <Row n="Site" v={item.site} />
          <Row n="Store" v={item.storeName} />
          <Row n="Ships to" v={item.shipTo} />
          <Row n="Not ships to" v={item.excludeLocation} />
          <Row n="Search keyword" v={node.term} />
          <Row n="Minimum to bid" v={item.minimumToBid.toString()} />
          <Row n="Started" v={item.startTime.toString()} />
          <Row n="Ends" v={item.endTime.toString()} />
        </Pressable>
      )}
    </View>
  );
};
