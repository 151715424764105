import React from "react";
import { Banner } from "react-native-paper";
import { useSelector } from "react-redux";

import { IUser } from "../interfaces/AppState";
import { IRootReducer } from "../reducers/allReducers";

export const SignUpBanner = ({
  navigation,
  signUpVisible,
  setSignUpVisible,
}: any) => {
  const user = useSelector<IRootReducer, IUser>((state) => state.appState.user);

  if (!user.isAnonymous) return null;

  return (
    <Banner
      contentStyle={{
        width: 350,
      }}
      visible={true}
      actions={[
        {
          label: signUpVisible ? "Cancel" : "Sign Up",
          onPress: () => {
            setSignUpVisible(!signUpVisible);
          },
        },
        {
          label: "Exit",
          onPress: () => {
            navigation.navigate("Settings");
          },
        },
      ]}
    >
      Create an account to avoid losing your data.
    </Banner>
  );
};
