import React from "react";
import { useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";
import { Pagination } from "./Pagination";
import { usePagination } from "./usePagination";

export const PaginationRecycler = ({
  totalCount,
  pageIndex,
  scrollToTop,
  loadItems,
  setPageIndex,
}: any) => {
  const resultsNumber = parseInt(
    useSelector<IRootReducer, string>(
      (state: { appState: { resultsNumber: any } }) =>
        state.appState.resultsNumber,
    ),
  );

  const pagination = usePagination({
    count: totalCount || 0,
    page: pageIndex,
    rowsPerPage: resultsNumber,
    rowsPerPageOptions: [resultsNumber, 30, 50],
  });

  const pressPageIndex = (index: number) => {
    scrollToTop();
    setPageIndex(index);
    loadItems({
      variables: {
        first: resultsNumber,
        skip: (index - 1) * resultsNumber,
        pageIndex: index,
      },
    });
  };
  return (
    <Pagination
      pagination={pagination}
      pressPageIndex={pressPageIndex}
      //@ts-ignore
      pageIndex={pageIndex}
    />
  );
};
