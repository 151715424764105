import React, { useState } from "react";
import { Checkbox, List } from "react-native-paper";

const DropdownItem = ({ id, name, onPress, checked }: any) => {
  const [enabled, setEnabled] = useState(checked);

  const toggle = () => {
    setEnabled(!enabled);
    onPress(id);
  };

  const checkbox = () => (
    <Checkbox key={id} status={enabled ? "checked" : "unchecked"} />
  );

  return (
    <List.Item
      onPress={toggle}
      style={{ width: 250 }}
      key={id}
      title={name}
      right={checkbox}
    />
  );
};

export { DropdownItem };
