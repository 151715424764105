import React from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";

import { CategoryChip } from "./CategoryChip";

export const CategoryChipList = ({
  textEditVisible,
  setTextEditVisible,
  selectedCats,
  onChange,
}: {
  textEditVisible: boolean;
  setTextEditVisible: any;
  selectedCats: any[];
  onChange: any;
}) => {
  return (
    <View
      style={{
        flexDirection: "column",
        flexShrink: 1,
        width: "100%",
      }}
    >
      {selectedCats &&
        selectedCats.map((category: any) => {
          const categoryIndex = selectedCats.findIndex(
            (c: any) => c.id === category.id,
          );
          const isLastChip = categoryIndex == selectedCats.length - 1;
          return (
            <View style={{ flexDirection: "row", flex: 1 }} key={category.id}>
              <CategoryChip
                category={category}
                onChange={onChange}
                selectedCats={selectedCats}
              />
              {/* Icon */}
              {selectedCats.length > 0 && isLastChip && (
                <IconButton
                  icon={textEditVisible ? "close" : "plus"}
                  style={{
                    alignSelf: "flex-end",
                    margin: 0,
                    marginLeft: 10,
                  }}
                  disabled={selectedCats.length >= 3}
                  onPress={() => {
                    setTextEditVisible(!textEditVisible);
                  }}
                />
              )}
            </View>
          );
        })}
    </View>
  );
};
