import * as React from "react";
import { Button, Dialog, MD3Colors, MD3LightTheme, Paragraph, Portal } from "react-native-paper";

interface dialogProps {
  visible: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DialogDeleteQuery = ({ visible, onClose, onDelete }: dialogProps) => (
  <Portal>
    <Dialog
      style={{
        minWidth: 300,
        maxWidth: 500,
        alignSelf: "center",
        paddingVertical: 10,
        paddingHorizontal: 20,
      }}
      onDismiss={onClose}
      visible={visible}
      dismissable={true}
    >
      <Dialog.Title>Delete search</Dialog.Title>
      <Dialog.Content>
        <Paragraph>Are you sure?</Paragraph>
      </Dialog.Content>

      <Dialog.Actions style={{ justifyContent: "space-between" }}>
        <Button onPress={onClose}>Cancel</Button>
        <Button
          mode="contained"
          onPress={() => {
            onDelete();
            onClose();
          }}
          buttonColor={MD3LightTheme.colors.error}
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  </Portal>
);

export { DialogDeleteQuery };
