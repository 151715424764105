import { useState } from "react";
import { useSelector } from "react-redux";

import { QueryHookOptions, useLazyQuery } from "@apollo/client";

import { termsQuery } from "../Graphql/Queries";
import { useTerms } from "../Graphql/useTerms";
import { Query } from "../interfaces/Query";
import { IRootReducer } from "../reducers/allReducers";
import { ConditionDict, GetConditionID } from "../Tools/ConditionDict";

export const useBackup = () => {
  const uid = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );
  const termQueryVars: QueryHookOptions = {
    variables: { uid, first: 1000 },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.terms) generateBackup(data.terms);
    },
  };
  const { createTerm, getTerms, showSearchesLimited } = useTerms();
  const terms = getTerms("cache-and-network");

  const [loadTerms, { loading }] = useLazyQuery(termsQuery, termQueryVars);

  const [backupStatus, setBackupStatus] = useState("");

  const array2csvRow = (cells: string[]) => {
    if (cells == null) return "";

    var csvCols = new Array();
    for (var i = 0; i <= cells.length - 1; i++) {
      var cell = cells[i] ?? "";
      var needQuotes =
        cell.indexOf(",") >= 0 ||
        cell.indexOf('"') >= 0 ||
        cell.indexOf("\r\n") >= 0;

      var needEquals = cell.startsWith("0");
      if (needEquals) needQuotes = true;

      cell = cell.replace(/"/g, '""');
      var cell = needQuotes ? '"' + cell + '"' : cell;
      if (needEquals) cell = "=" + cell;

      csvCols.push(cell);
    }

    return csvCols.join(",");
  };

  const generateBackup = (terms: Query[]) => {
    const csvHeaders = [
      "alias",
      "keyword",
      "enabled",
      "searchInDescription",
      "priceMin",
      "priceMax",
      "categories",
      "condition",
      "site",
      "locatedIn",
      "availableTo",
      "zip",
      "sellerList",
      "sellerFilter",
    ];
    var csvRows = new Array();
    csvRows.push(csvHeaders);
    var searchesCount = 0;
    terms.forEach((t: Query) => {
      const selectedCategories = t.categories ? JSON.parse(t.categories) : [];
      const categoryIdArray = selectedCategories.map((category: any) => {
        return category.id;
      });

      const conditionIDs = t.condition.split(",");
      const conditionNames = new Array();
      conditionIDs.forEach((id) => {
        conditionNames.push(ConditionDict[id]);
      });

      const categoryIDStr = categoryIdArray.join(",");
      const termProps = [
        t.alias.toString(),
        t.keyword.toString(),
        t.enabled.toString(),
        t.searchInDescription.toString(),
        t.priceMin.toString(),
        t.priceMax.toString(),
        categoryIDStr,
        conditionNames.join(","),
        t.site.toString(),
        t.locatedIn.toString(),
        t.availableTo.toString(),
        t.zip.toString(),
        t.sellerList.toString(),
        t.sellerFilter.toString(),
      ];
      const row = array2csvRow(termProps);
      csvRows.push(row);
      searchesCount++;
    });

    var link = window.document.createElement("a");
    link.download = "SearchesExport.csv";
    link.href = "data:," + csvRows.join("\r\n");
    link.click();
    setBackupStatus(`Searches exported: ${searchesCount}`);
  };

  function parseCSV(str: string | any[]) {
    var arr: any[][] = [];
    var quote = false; // true means we're inside a quoted field

    // iterate over each character, keep track of current row and column (of the returned array)
    for (var row = 0, col = 0, c = 0; c < str.length; c++) {
      var cc = str[c],
        nc = str[c + 1]; // current character, next character
      arr[row] = arr[row] || []; // create a new row if necessary
      arr[row][col] = arr[row][col] || ""; // create a new column (start with empty string) if necessary

      // If the current character is a quotation mark, and we're inside a
      // quoted field, and the next character is also a quotation mark,
      // add a quotation mark to the current column and skip the next character
      if (cc == '"' && quote && nc == '"') {
        arr[row][col] += cc;
        ++c;
        continue;
      }

      // If it's just one quotation mark, begin/end quoted field
      if (cc == '"') {
        quote = !quote;
        continue;
      }

      // If it's a comma and we're not in a quoted field, move on to the next column
      if (cc == "," && !quote) {
        ++col;
        continue;
      }

      // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
      // and move on to the next row and move to column 0 of that new row
      if (cc == "\r" && nc == "\n" && !quote) {
        ++row;
        col = 0;
        ++c;
        continue;
      }

      // If it's a newline (LF or CR) and we're not in a quoted field,
      // move on to the next row and move to column 0 of that new row
      if (cc == "\n" && !quote) {
        ++row;
        col = 0;
        continue;
      }
      if (cc == "\r" && !quote) {
        ++row;
        col = 0;
        continue;
      }

      // Otherwise, append the current character to the current column
      arr[row][col] += cc;
    }
    return arr;
  }
  const importDesktopSearches = () => {
    if (terms.length >= 30) {
      showSearchesLimited();
      return;
    }
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = (e) => {
      var reader = new FileReader();

      reader.onload = function (e) {
        var content = reader.result;
        if (content) {
          const table = parseCSV(content.toString().replace(/=\"/g, '"'));
          table.shift();
          var searchCount = 0;
          table.forEach((row) => {
            const categoriesArr = row[7].split(",").map((id: string) => {
              return { id, name: "", path: "" };
            });

            const conditionArr = row[8].split(",").map((name: string) => {
              if (name) return GetConditionID(name);
            });

            let sellerFilter = "Disabled";

            if (row[14].trim() !== "") {
              sellerFilter = row[14].trim();
            }

            if (row[1].trim() !== "") {
              return;
            }

            const q: Query = {
              alias: row[0].trim(),
              keyword: row[2].trim(),
              enabled: row[3].toLowerCase().trim() === "true",
              searchInDescription: row[4].toLowerCase().trim() === "true",
              priceMin: parseFloat(row[5]),
              priceMax: parseFloat(row[6]),
              categories: JSON.stringify(categoriesArr),
              condition: conditionArr.join(","),
              site: row[9].trim(),
              locatedIn: row[10].trim(),
              availableTo: row[11].trim(),
              zip: row[12].trim(),
              sellerList: row[13].trim(),
              sellerFilter: sellerFilter,
              action: "",
              categoriesName: "",
              id: "-1",
              listingType: "",
              minFeedbackPercent: 0,
              minFeedbackCount: 0,
            };

            if (searchCount >= 30) {
              showSearchesLimited();
              return;
            } else {
              searchCount++;
              createTerm(q);
            }
          });
          setBackupStatus(`Searches imported: ${searchCount}`);
        }
      };

      //@ts-ignore
      if (e?.target?.files !== null && e?.target?.files.length > 0)
        //@ts-ignore
        reader.readAsText(e?.target?.files[0]);
    };
    input.click();
  };
  const importSearches = () => {
    if (terms.length >= 30) {
      showSearchesLimited();
      return;
    }
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = (e) => {
      var reader = new FileReader();

      reader.onload = function (e) {
        var content = reader.result;
        if (content) {
          const table = parseCSV(content.toString().replace(/=\"/g, '"'));

          table.shift();
          var searchCount = 0;
          table.forEach((row) => {
            const categoriesArr = row[6].split(",").map((id: string) => {
              return { id, name: "", path: "" };
            });

            const conditionArr = row[7].split(",").map((name: string) => {
              if (name) return GetConditionID(name);
            });

            const q: Query = {
              alias: row[0].trim(),
              keyword: row[1].trim(),
              enabled: row[2].toLowerCase().trim() === "true",
              searchInDescription: row[3].toLowerCase().trim() === "true",
              priceMin: parseFloat(row[4]),
              priceMax: parseFloat(row[5]),
              categories: JSON.stringify(categoriesArr),
              condition: conditionArr.join(","),
              site: row[8].trim(),
              locatedIn: row[9].trim(),
              availableTo: row[10].trim(),
              zip: row[11].trim(),
              sellerList: row[12].trim(),
              sellerFilter: row[13].trim(),
              action: "",
              categoriesName: "",
              id: "-1",
              listingType: "",
              minFeedbackPercent: 0,
              minFeedbackCount: 0,
            };

            if (searchCount >= 30) {
              showSearchesLimited();
              return;
            } else {
              searchCount++;
              createTerm(q);
            }
          });
          setBackupStatus(`Searches imported: ${searchCount}`);
        }
      };

      //@ts-ignore
      if (e?.target?.files !== null && e?.target?.files.length > 0)
        //@ts-ignore
        reader.readAsText(e?.target?.files[0]);
    };
    input.click();
  };

  const exportSearches = () => {
    loadTerms({
      variables: { uid, first: 1000 },
    });
  };

  return {
    importSearches,
    importDesktopSearches,
    exportSearches,
    backupStatus,
    loading,
  };
};
