import React from "react";
import { View } from "react-native";
import { Button, Surface } from "react-native-paper";

export const Pagination = ({ pagination, pressPageIndex, pageIndex }: any) => {
  if (pagination.pageCount < 2) return null;

  return (
    <Surface
      style={{
        elevation: 9,
        padding: 5,
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <Button
          disabled={!pagination.showPrevious}
          onPress={() => pressPageIndex(pageIndex - 1)}
        >
          {pagination.showPrevious ? "<" : ""}
        </Button>

        {pagination.showPagination &&
          pagination.pageCount &&
          pagination.pages[0] !== 1 && (
            <Button
              compact
              disabled={!pagination.showPrevious}
              onPress={() => pressPageIndex(1)}
            >
              1
            </Button>
          )}
        {pagination.showPagination &&
          pagination.pageCount > 0 &&
          pagination.pages.map((page: string) => {
            return (
              <Button
                compact
                key={page}
                onPress={() => pressPageIndex(page)}
                mode={page == pageIndex ? "outlined" : "text"}
              >
                {page}
              </Button>
            );
          })}

        {pagination.pages.length > 0 &&
          pagination.pages[pagination.pages.length - 1] !==
            pagination.pageCount && (
            <Button
              compact
              onPress={() => pressPageIndex(pagination.pageCount)}
            >
              {pagination.pageCount}
            </Button>
          )}

        <Button
          disabled={!pagination.showNext}
          onPress={() => pressPageIndex(pageIndex + 1)}
        >
          {pagination.showNext ? ">" : ""}
        </Button>
      </View>
    </Surface>
  );
};
