import { Alert } from "react-native";
import { useSelector } from "react-redux";

import {
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy
} from "@apollo/client";

import { Query } from "../interfaces/Query";
import { Platform } from "../Platform";
import { IRootReducer } from "../reducers/allReducers";
import { addTermMutation, deleteTermMutation, termsQuery, updateTermMutation } from "./Queries";

export const useTerms = () => {
  const uid = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid
  );

  const termQueryVars: QueryHookOptions = {
    variables: { uid, first: 30 },
    fetchPolicy: "cache-and-network",
  };
  const termQueryVarsCache: QueryHookOptions = {
    variables: { uid, first: 30 },
    fetchPolicy: "cache-only",
  };
  const termQueryVarsNetwork: QueryHookOptions = {
    variables: { uid, first: 30 },
    fetchPolicy: "network-only",
  };

  const getTerms = (fetchPolicy: WatchQueryFetchPolicy) => {
    const termQueryVars: QueryHookOptions = {
      variables: { uid, first: 30 },
      fetchPolicy: fetchPolicy,
    };
    const { data } = useQuery(termsQuery, termQueryVars);
    if (data && data.terms) return data.terms;
    return null;
  };

  const loadTerms = () => {
    const [loadTerms] = useLazyQuery(termsQuery, { ...termQueryVars });
    return loadTerms;
  };

  const [addTermHook] = useMutation(addTermMutation);

  const [updateTermHook] = useMutation(updateTermMutation);

  const [deleteTermHook] = useMutation(deleteTermMutation);

  const createTerm = (term: Query) => {
    //@ts-ignore
    delete term.id;
    //@ts-ignore
    delete term.uid;
    //@ts-ignore
    delete term.createdAt;
    //@ts-ignore
    delete term.updatedAt;
    //@ts-ignore
    delete term.__typename;
    const variables = {
      data: {
        ...term,
        user: {
          connectOrCreate: {
            create: {
              uid
            },
            where: {
              uid
            }
          }
        }
      },
    };

    addTermHook({
      variables,
      update: (store, { data: term }) => {
        if (!term.createOneTerm.id) {
          term.createOneTerm.id = (Math.random() * -1000000).toString();
        }
        const data: any = store.readQuery({
          query: termsQuery,
          ...termQueryVars,
        });
        store.writeQuery({
          query: termsQuery,
          ...termQueryVars,
          data: { ...data, terms: [term.createOneTerm, ...data.terms] },
        });
      },
      optimisticResponse: {
        __typename: "Mutation",
        createOneTerm: {
          ...term,
          uid,
          id: (Math.random() * -1000000).toString(),
          createdAt: "",
          updatedAt: "",
          __typename: "Term",
        },
      },
    }).catch((e: any) => {
      console.log("createOneTerm -> ", e);
    });
  };

  const updateTerm = (term: Query) => {
    //@ts-ignore
    delete term.__typename;
    //@ts-ignore
    delete term.uid;
    //@ts-ignore
    delete term.updatedAt;

    const variables = {
      data: {
        action: { set: term.action },
        alias: { set: term.alias },
        availableTo: { set: term.availableTo },
        categories: { set: term.categories },
        categoriesName: { set: term.categoriesName },
        condition: { set: term.condition },
        enabled: { set: term.enabled },
        keyword: { set: term.keyword },
        listingType: { set: term.listingType },
        locatedIn: { set: term.locatedIn },
        minFeedbackCount: { set: term.minFeedbackCount },
        minFeedbackPercent: { set: term.minFeedbackPercent },
        priceMax: { set: term.priceMax },
        priceMin: { set: term.priceMin },
        searchInDescription: { set: term.searchInDescription },
        sellerFilter: { set: term.sellerFilter },
        sellerList: { set: term.sellerList },
        site: { set: term.site },
        zip: { set: term.zip },
        user: { connect: { uid } },
      },
      where: { id: term.id },
    };

    updateTermHook({
      variables,
      optimisticResponse: {
        updateOneTerm: {
          ...term,
          uid,
          updatedAt: new Date().toISOString(),
          __typename: "Term",
        },
      },
    }).catch((e: any) => {
      console.log("createTerm -> ", e);
    });
  };

  const deleteTerm = (termID: string) => {
    const variables = { data: { id: termID, uid: { equals: uid } } };
    deleteTermHook({
      variables,
      update: (store, { data: term }) => {
        const data: any = store.readQuery({
          query: termsQuery,
          ...termQueryVars,
        });

        const filteredTerms = data.terms.filter(
          (t: Query) => t.id !== term.deleteOneTerm.id
        );

        store.writeQuery({
          query: termsQuery,
          ...termQueryVars,
          data: {
            terms: [...filteredTerms],
          },
        });
      },
      optimisticResponse: {
        __typename: "Mutation",
        deleteOneTerm: {
          id: termID,
          __typename: "Term",
        },
      },
    }).catch((e: any) => {
      console.log("deleteOneTerm -> ", e);
    });
  };

  const showSearchesLimited = () => {
    const message = "You have reached maximum keyword number limit: 30";

    switch (Platform.OS) {
      case "web":
        alert(message);
        break;
      default:
        Alert.alert(message);
        break;
    }
  };

  return {
    getTerms,
    loadTerms,
    createTerm,
    updateTerm,
    deleteTerm,
    showSearchesLimited,
  };
};
