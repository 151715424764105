//import { getUniqueId } from "react-native-device-info";

import * as Application from "expo-application";
import Constants from "expo-constants";

import { Platform } from "../Platform";

export const getDeviceID = async () => {
  let uniqueId = Constants.installationId;

  if (Platform.OS === "ios") {
    let iosId = await Application.getIosIdForVendorAsync();
    uniqueId = iosId ?? uniqueId;
  }

  if (Platform.OS === "android") {
    uniqueId = Application.androidId ?? uniqueId;
  }

  if (Platform.OS === "web") {
    uniqueId = Math.round(new Date().getTime()).toString();
  }

  return uniqueId;
};
