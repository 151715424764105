import { Linking, Platform } from "react-native";

//import InAppBrowser from "react-native-inappbrowser-reborn";

export function openUrl(url: string) {
  console.log("TCL: openUrl -> url", url);
  if (Platform.OS === "web") {
    window.open(url, "_blank");
    return;
  }

  Linking.openURL(url);
}

// const OpenInAppBrowser = async (url: string) => {
//   if (await InAppBrowser.isAvailable()) {
//     await InAppBrowser.open(url, {
//       // iOS Properties
//       dismissButtonStyle: "cancel",
//       preferredBarTintColor: "#1565c0",
//       preferredControlTintColor: "white",
//       readerMode: false,
//       animated: true,
//       modalPresentationStyle: "overFullScreen",
//       modalTransitionStyle: "partialCurl",
//       modalEnabled: true,
//       enableBarCollapsing: false,
//       // Android Properties
//       showTitle: false,
//       toolbarColor: "#1565c0",
//       secondaryToolbarColor: "black",
//       enableUrlBarHiding: true,
//       enableDefaultShare: false,
//       forceCloseOnRedirection: true,
//       // Specify full animation resource identifier(package:anim/name)
//       // or only resource name(in case of animation bundled with app).
//       animations: {
//         startEnter: "slide_in_right",
//         startExit: "slide_out_left",
//         endEnter: "slide_in_left",
//         endExit: "slide_out_right",
//       },
//     }).then((a) => {
//       console.log("TCL: InAppBrowser.opened", a);
//     });
//   }
// };
