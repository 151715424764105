import { get, set } from "lodash";
import * as Yup from "yup";

export function makeValidate(validator: Yup.SchemaOf<any>) {
  return async (values: any) => {
    try {
      await validator.validate(values, { abortEarly: false });
    } catch (err) {
      //@ts-ignore
      return err.inner.reduce((errors: any, { path, message }: any) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + " " + message);
        } else {
          set(errors, path, message);
        }
        return errors;
      }, {});
    }
  };
}

Yup.addMethod(Yup.object, "atLeastOneOf", function (list: any) {
  //@ts-ignore
  return this.test({
    name: "atLeastOneOf",
    message: "Please, set Keyword or Category",
    exclusive: true,
    params: {
      keys: list.join(", "),
    },
    test: (value: { [x: string]: string; } | null | undefined) => {
      const result =
        value === undefined ||
        value == null ||
        list.some((f: React.ReactText) => value[f] != null && value[f] !== "");
      return result;
    },
  });
});
const schema = Yup.object({
  alias: Yup.string().required().max(50).label("Alias").trim(),

  keyword: Yup.string()
    .max(350)
    .label("Keyword")
    .trim()
    .test(
      "empty-string",
      "Please, enter at least 2 letters",
      (value: string | any[] | null | undefined) => {
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          value.length >= 2
        );
      },
    )
    .test(
      "cant-start-with-chars",
      "Keyword can't start with '-' or '*'",
      (value: string | string[] | null | undefined) =>
        value === undefined ||
        value === null ||
        value === "" ||
        (value[0] != "-" && value[0] != "*"),
    ),
  //.matches(/^[^\-\*]*$/, "Keyword can't start with '-' or '*'"), //doesn't start with "-" "*a"

  priceMin: Yup.number().required().min(0.01).label("Minimum Price"),
  priceMax: Yup.number()
    .required()
    .min(0.01)
    .max(10000000)
    .label("Maximum Price"),

  sellerList: Yup.string().label("Sellers").trim(),
  //@ts-ignore
}).atLeastOneOf(["keyword", "categories"]);
//.oneOf(["keyword", "categories"]);

export const queryValidator = makeValidate(schema);
