import { useEffect, useState } from "react";

import { FirebaseAuthTypes } from "@react-native-firebase/auth";

import { auth } from "../firebase/firebase_config";
import { useAuth } from "../hooks/useAuth";

const useFirebaseAuth = () => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseAuthTypes.User>();
  const { signOut } = useAuth();
  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setFirebaseUser(authUser);
      } else {
        signOut(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return { firebaseUser };
};

export { useFirebaseAuth };
