import React, { memo, useState } from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Divider,
  Paragraph,
  Subheading,
  Surface,
  Switch,
  TouchableRipple,
} from "react-native-paper";

import { useTerms } from "../Graphql/useTerms";
import { Query } from "../interfaces/Query";
import { DialogDeleteQuery } from "./DialogDeleteQuery";
import { QueryMenu } from "./QueryMenu";

const SearchQuery1 = (props: { searchQuery: Query; navigation: any }) => {
  const { createTerm, updateTerm, deleteTerm } = useTerms();
  var query: Query = props.searchQuery;
  const categories = query.categories ? JSON.parse(query.categories) : [];
  const categoryIDs = categories.map((c: any) => c.id);

  const editQuery = () =>
    props.navigation.navigate({
      name: "QueryEdit",
      params: { query: query },
    });

  const copyQuery = () => {
    const queryCopy = {
      ...query,
      enabled: false,
      alias: query.alias + " Copy",
    };

    createTerm(queryCopy);
  };
  const toggleStateQuery = () => {
    updateTerm({ ...query, enabled: !query.enabled });
  };

  const toggleStateQueryMobile = () => {
    if (Platform.OS !== "web") {
      toggleStateQuery();
    }
  };

  const deleteQueryAction = () => {
    deleteTerm(query.id);
  };
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const showDeleteDialog = () => setDeleteDialogVisible(true);
  const closeDeleteDialog = () => setDeleteDialogVisible(false);

  return (
    <Surface
      style={{
        elevation: 1,
        marginHorizontal: 10,
        marginVertical: 5,
        borderRadius: 4,
        paddingVertical: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          style={{
            borderWidth: 0,
            justifyContent: "center",
            marginHorizontal: 16,
          }}
          onPress={toggleStateQuery}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 2,
              margin: 2,
            }}
          >
            <Switch
              value={query.enabled}
              onValueChange={toggleStateQueryMobile}
            />
          </View>
        </TouchableOpacity>

        <TouchableRipple
          onPress={editQuery}
          style={{
            opacity: query.enabled ? 1 : 0.4,
            flex: 1,
          }}
        >
          <View>
            <Subheading numberOfLines={1} style={{ fontWeight: "bold" }}>
              {query.alias}
            </Subheading>
            <Divider />

            <View style={{ flexDirection: "row" }}>
              <View style={{ marginRight: 20, opacity: 0.5 }}>
                {query.keyword?.length > 0 && (
                  <Paragraph style={{ fontStyle: "italic" }}>Keyword</Paragraph>
                )}
                {categoryIDs.length > 0 && (
                  <Paragraph style={{ fontStyle: "italic" }}>
                    Category
                  </Paragraph>
                )}
                <Divider />
              </View>
              <View style={{ flex: 1 }}>
                {query.keyword?.length > 0 && (
                  <Paragraph numberOfLines={1}>{query.keyword}</Paragraph>
                )}
                {categoryIDs.length > 0 && (
                  <Paragraph>{categoryIDs.join(", ")}</Paragraph>
                )}
              </View>
            </View>
          </View>
        </TouchableRipple>
        <View style={{ justifyContent: "center" }}>
          <QueryMenu
            queryEnabled={query.enabled}
            onToggle={toggleStateQuery}
            onEdit={editQuery}
            onCopy={copyQuery}
            onDelete={showDeleteDialog}
          />
          <DialogDeleteQuery
            visible={deleteDialogVisible}
            onDelete={deleteQueryAction}
            onClose={closeDeleteDialog}
          />
        </View>
      </View>
    </Surface>
  );
};
const SearchQuery = memo(SearchQuery1);

export { SearchQuery };

const myBorderWidth = 1;
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    //alignItems: "stretch",
    paddingTop: 65,
    backgroundColor: "#F5FCFF",
  },
  formInput: {
    //borderBottomWidth: 1.5,
    //marginLeft: 20,
    borderColor: "#333",
    borderWidth: myBorderWidth,
  },

  input: {
    //width: "50%",
    //textAlign: "center",
    color: "#333333",
    //margin: 35,
    borderColor: "green",
    fontSize: 16,
    height: 40,
    borderWidth: myBorderWidth,
    //borderBottomWidth: 1.5,
  },
  labelInput: {
    color: "#673AB7",
  },
  label: {
    //fontSize: 20,
    //textAlign: "center",
    //margin: 10,
    borderColor: "#000000",
    borderWidth: myBorderWidth,
  },
  rowFront: {
    alignItems: "center",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    height: 50,
  },
});
