import React from "react";
import { ScrollView } from "react-native-gesture-handler";

import { ConditionDict } from "../../Tools/ConditionDict";
import { DropdownItem } from "./DropdownItem";

const DropdownItems = ({
  checkedItemsRef,
}: {
  checkedItemsRef: React.MutableRefObject<any>;
}) => {
  const addOrRemoveItem = (list: string, item: string, sep = ",") => {
    let newArray = list ? list.split(sep) : [];

    if (newArray.includes(item)) {
      //remove
      {
        newArray = newArray.filter((i) => i != item);
      }
    } else {
      //add
      newArray = [...newArray, item];
    }
    return newArray.join(sep);
  };

  const itemPress = (changedItem: string) => {
    const checkedItems = addOrRemoveItem(checkedItemsRef.current, changedItem);
    checkedItemsRef.current = checkedItems;
  };

  return (
    <ScrollView
      scrollEnabled={true}
      showsVerticalScrollIndicator={true}
      keyboardShouldPersistTaps="always"
    >
      {Object.entries(ConditionDict).map((conditionObj: any) => {
        const checkedConditions: string = checkedItemsRef.current;
        const checked = checkedConditions?.includes(conditionObj[0]);

        return (
          <DropdownItem
            key={conditionObj[0]}
            id={conditionObj[0]}
            name={conditionObj[1]}
            checked={checked}
            onPress={itemPress}
          />
        );
      })}
    </ScrollView>
  );
};

export { DropdownItems };
