interface IEBaySite {
  code: string;
  language: string;
  shortcode: string;
  name: string;
  id: string;
  country: string;
  domain: string;
  roverlink: string;
}
export const eBaySites: IEBaySite[] = [
  {
    code: "EBAY-US",
    language: "en-US",
    shortcode: "US",
    name: "eBay US",
    id: "0",
    country: "US",
    domain: "www.ebay.com",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-IE",
    language: "en-IE",
    shortcode: "IE",
    name: "eBay Ireland",
    id: "205",
    country: "Ireland",
    domain: "www.ebay.ie",
    roverlink:
      "https://rover.ebay.com/rover/1/5282-53468-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229543",
  },
  {
    code: "EBAY-AT",
    language: "de-AT",
    shortcode: "AT",
    name: "eBay Austria",
    id: "16",
    country: "Austria",
    domain: "www.ebay.at",
    roverlink:
      "https://rover.ebay.com/rover/1/5221-53469-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229473",
  },
  {
    code: "EBAY-AU",
    language: "en-AU",
    shortcode: "AU",
    name: "eBay Australia",
    id: "15",
    country: "Australia",
    domain: "www.ebay.com.au",
    roverlink:
      "https://rover.ebay.com/rover/1/705-53470-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229515",
  },
  {
    code: "EBAY-FRBE",
    language: "fr-BE",
    shortcode: "BE",
    name: "eBay Belgium (French)",
    id: "23",
    country: "Belgium_French",
    domain: "www.befr.ebay.be",
    roverlink:
      "https://rover.ebay.com/rover/1/1553-53471-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229522",
  },
  {
    code: "EBAY-NLBE",
    language: "nl-BE",
    shortcode: "BE",
    name: "eBay Belgium (Dutch)",
    id: "123",
    country: "Belgium_Dutch",
    domain: "www.benl.ebay.be",
    roverlink:
      "https://rover.ebay.com/rover/1/1553-53471-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229522",
  },
  {
    code: "EBAY-ENCA",
    language: "en-CA",
    shortcode: "CA",
    name: "eBay Canada (English)",
    id: "2",
    country: "Canada",
    domain: "www.ebay.ca",
    roverlink:
      "https://rover.ebay.com/rover/1/706-53473-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229529",
  },
  {
    code: "EBAY-FR",
    language: "fr-FR",
    shortcode: "FR",
    name: "eBay France",
    id: "71",
    country: "France",
    domain: "www.ebay.fr",
    roverlink:
      "https://rover.ebay.com/rover/1/709-53476-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229480",
  },
  {
    code: "EBAY-DE",
    language: "en-DE",
    shortcode: "DE",
    name: "eBay Germany",
    id: "77",
    country: "Germany",
    domain: "www.ebay.de",
    roverlink:
      "https://rover.ebay.com/rover/1/707-53477-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229487",
  },
  {
    code: "EBAY-IT",
    language: "it-IT",
    shortcode: "IT",
    name: "eBay Italy",
    id: "101",
    country: "Italy",
    domain: "www.ebay.it",
    roverlink:
      "https://rover.ebay.com/rover/1/724-53478-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229494",
  },
  {
    code: "EBAY-ES",
    language: "es-ES",
    shortcode: "ES",
    name: "eBay Spain",
    id: "186",
    country: "Spain",
    domain: "www.ebay.es",
    roverlink:
      "https://rover.ebay.com/rover/1/1185-53479-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229501",
  },
  {
    code: "EBAY-CH",
    language: "de-CH",
    shortcode: "CH",
    name: "eBay Switzerland",
    id: "193",
    country: "Switzerland",
    domain: "www.ebay.ch",
    roverlink:
      "https://rover.ebay.com/rover/1/5222-53480-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229536",
  },
  {
    code: "EBAY-GB",
    language: "en-GB",
    shortcode: "GB",
    name: "eBay UK",
    id: "3",
    country: "UK",
    domain: "www.ebay.co.uk",
    roverlink:
      "https://rover.ebay.com/rover/1/710-53481-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229508",
  },
  {
    code: "EBAY-NL",
    language: "nl-NL",
    shortcode: "NL",
    name: "eBay Netherlands",
    id: "146",
    country: "Netherlands",
    domain: "www.ebay.nl",
    roverlink:
      "https://rover.ebay.com/rover/1/1346-53482-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229557",
  },
  {
    code: "EBAY-FRCA",
    language: "fr-CA",
    shortcode: "CA",
    name: "eBay Canada (French)",
    id: "210",
    country: "CanadaFrench",
    domain: "www.ebay.ca",
    roverlink:
      "https://rover.ebay.com/rover/1/706-53473-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229529",
  },
  {
    code: "EBAY-MOTOR",
    language: "en-US",
    shortcode: "US",
    name: "eBay Motors",
    id: "100",
    country: "eBayMotors",
    domain: "www.ebay.com",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-HK",
    language: "zh-Hant",
    shortcode: "HK",
    name: "eBay Hong Kong",
    id: "201",
    country: "HongKong",
    domain: "www.ebay.com.hk",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-IN",
    language: "en-IN",
    shortcode: "IN",
    name: "eBay India",
    id: "203",
    country: "India",
    domain: "www.ebay.in",
    roverlink:
      "https://rover.ebay.com/rover/1/4686-53472-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229550",
  },
  {
    code: "EBAY-MY",
    language: "en-MY",
    shortcode: "MY",
    name: "eBay Malaysia",
    id: "207",
    country: "Malaysia",
    domain: "www.ebay.com.my",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-PH",
    language: "en-PH",
    shortcode: "PH",
    name: "eBay Philippines",
    id: "211",
    country: "Philippines",
    domain: "www.ebay.ph",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-PL",
    language: "pl-PL",
    shortcode: "PL",
    name: "eBay Poland",
    id: "212",
    country: "Poland",
    domain: "www.ebay.pl",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
  {
    code: "EBAY-SG",
    language: "en-SG",
    shortcode: "SG",
    name: "eBay Singapore",
    id: "216",
    country: "Singapore",
    domain: "www.ebay.com.sg",
    roverlink:
      "https://rover.ebay.com/rover/1/711-53200-19255-0/1?ff3=2&lgeo=1&toolid=10001&campid=5337536011&vectorid=229466",
  },
];
