import React from "react";
import { TouchableOpacity } from "react-native";
import { IconButton } from "react-native-paper";

export const CloseButton = ({ action, location }: any) => {
  const backgroundColor = "rgba(0,0,0,0.001)";
  const iconColor = "rgba(255,255,255,0.5)";
  return (
    <TouchableOpacity
      style={{
        backgroundColor,
        borderRadius: 100,
        position: "absolute",
        top: 5,
        left: 5,
        zIndex: 9,
        ...location,
      }}
      onPress={action}
      accessibilityRole="none"
    >
      <IconButton
        icon="close"
        size={40}
        onPress={action}
        iconColor={iconColor}
      />
    </TouchableOpacity>
  );
};
