import * as Brightness from "expo-brightness";
import { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";

export const useBrightness = () => {
  const dispatch = useDispatch();

  const brightnessEnabled = useSelector<IRootReducer, boolean>(
    (state) => state.appState.brightnessEnabled,
  );
  const appBrightnessLevel = useSelector<IRootReducer, number>(
    (state) => state.appState.brightnessLevel,
  );

  useEffect(() => {
    (async () => {
      if (brightnessEnabled) {
        await Brightness.setBrightnessAsync(appBrightnessLevel);
      } else {
        await Brightness.useSystemBrightnessAsync();
      }
    })();
  }, [brightnessEnabled, appBrightnessLevel]);

  const applyBrightnessOnSliderChange = async (brightnessLevel: number) => {
    dispatch({
      type: "SET_BRIGHTNESS_LEVEL",
      payload: brightnessLevel,
    });
  };

  const toggleBrightnessEnabled = async () => {
    dispatch({
      type: "SET_BRIGHTNESS_ENABLED",
      payload: !brightnessEnabled,
    });
  };
  const applyBrightnessOnAppStart = () => {};
  const restoreSystemBrightnessOnShutdown = () => {};

  return {
    brightnessEnabled,
    appBrightnessLevel,
    applyBrightnessOnSliderChange,
    toggleBrightnessEnabled,
    applyBrightnessOnAppStart,
    restoreSystemBrightnessOnShutdown,
  };
};
