import React from "react";
import { Chip } from "react-native-paper";

export const CategoryChip = ({
  category,
  selectedCats,
  onChange,
}: {
  category: any;
  selectedCats: any[];
  onChange: (arg: any) => Record<string, unknown>;
}) => {
  let chipText = "";
  if (category.name) {
    if (category.name.indexOf(category.id) < 0)
      chipText = category.name + " (" + category.id + ")";
    else {
      chipText = category.name;
    }
  } else {
    chipText = category.id ? category.id : "";
  }

  return (
    <Chip
      onClose={() => {
        const filteredCategories = selectedCats.filter(
          (c: any) => c.id != category.id,
        );
        onChange(JSON.stringify(filteredCategories));
      }}
      onPress={() => {}}
      style={{ alignItems: "center" }}
    >
      {chipText}
    </Chip>
  );
};
