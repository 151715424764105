import React, { useState } from "react";
import { Dimensions, Platform } from "react-native";

import { WebView } from "../Item/WebView";

export const WebViewAutoHeight = (props: { source: any }) => {
  const [webViewHeight, setWebViewHeight] = useState(150);

  const onWebViewMessage = (event: any) => {
    setWebViewHeight(Number(event.nativeEvent.data));
  };

  return (
    <WebView
      onMessage={onWebViewMessage}
      injectedJavaScript="window.ReactNativeWebView.postMessage(document.body.scrollHeight)"
      source={props.source}
      style={{
        width: Dimensions.get("window").width,
        height: Platform.OS === "web" ? 500 : webViewHeight,
      }}
    />
  );
};
