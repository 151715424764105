import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { StyleSheet, View } from "react-native";
import { HelperText, TextInput } from "react-native-paper";

import { LightTheme } from "../Theme/LightTheme";

export const LoginPassword = (props: any) => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setShown(true);
  }, []);

  return (
    <View>
      {shown ? (
        <>
          <Field name="email">
            {({ input, meta }) => (
              <>
                <TextInput
                  onChangeText={(e) => {
                    input.onChange(e.trim());
                  }}
                  //@ts-ignore

                  onBlur={input.onBlur}
                  //@ts-ignore
                  onFocus={input.onFocus}
                  value={input.value}
                  style={styles.textInput}
                  theme={LightTheme}
                  label="Email"
                  textContentType="emailAddress"
                  autoCompleteType="email"
                  autoCapitalize="none"
                  autoCorrect={false}
                  importantForAutofill={"yes"}
                  keyboardType="default"
                />

                {meta.touched && (
                  <HelperText type="error" visible={meta.touched && meta.error}>
                    {meta.error}
                  </HelperText>
                )}
              </>
            )}
          </Field>
          <Field name="password">
            {({ input, meta }) => (
              <>
                <TextInput
                  onChangeText={input.onChange}
                  onChange={input.onChange}
                  //@ts-ignore
                  onBlur={input.onBlur}
                  ///@ts-ignore
                  onFocus={input.onFocus}
                  value={input.value}
                  style={styles.textInput}
                  theme={LightTheme}
                  label="Password"
                  textContentType="password"
                  autoCompleteType="password"
                  autoCapitalize="none"
                  secureTextEntry={true}
                  importantForAutofill={"yes"}
                  autoCorrect={false}
                  onSubmitEditing={props.handleSubmit}
                  keyboardType="default"
                />

                {meta.touched && (
                  <HelperText type="error" visible={meta.touched && meta.error}>
                    {meta.error}
                  </HelperText>
                )}
              </>
            )}
          </Field>
        </>
      ) : null}
    </View>
  );
};

export const styles = StyleSheet.create({
  textInput: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
});
