import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { GalleryThumbnail } from "./GalleryThumbnail";

export const ThumbnailRow = ({
  images,
  currentIndex,
  setIndex,
}: {
  images: readonly unknown[] | [];
  currentIndex: number;
  setIndex: (arg0: number) => any;
}) => {
  const flatListRef = useRef<FlatList<unknown | null>>(null);
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);
  const [isInitiated, setIsInitiated] = useState(false);

  const onViewRef = React.useRef((viewableItems: any) => {
    const indexes = viewableItems.viewableItems.map(
      (item: { index: any }) => item.index,
    );
    setVisibleIndexes(indexes);
    setIsInitiated(true);
  });
  const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 90 });

  useEffect(() => {
    if (visibleIndexes.length === 0 || !isInitiated) return;

    const needShiftRight =
      Math.max(...visibleIndexes) <
      Math.min(currentIndex + 1, images.length - 1);

    const needShiftLeft =
      Math.min(...visibleIndexes) > Math.max(0, currentIndex - 1);

    let targetIndex = currentIndex;
    if (needShiftRight) {
      targetIndex =
        currentIndex < images.length - 1
          ? Math.min(currentIndex + 1, images.length)
          : currentIndex;
    }
    if (needShiftLeft) {
      targetIndex =
        currentIndex >= 0 ? Math.max(0, currentIndex - 1) : currentIndex;
    }

    if (!needShiftLeft && !needShiftRight) return;
    if (flatListRef.current?.props?.data?.length === 0) return;

    flatListRef.current?.scrollToIndex({
      animated: true,
      index: targetIndex,
      viewPosition: needShiftRight ? 1 : 0,
    });
  }, [currentIndex, isInitiated]);

  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginHorizontal: 5,
        paddingVertical: 5,
      }}
    >
      <FlatList
        onScrollToIndexFailed={() => {}}
        ref={flatListRef}
        style={{
          flexDirection: "row",
          flexGrow: 0,
          height: 80,
        }}
        data={images}
        onViewableItemsChanged={onViewRef.current}
        viewabilityConfig={viewConfigRef.current}
        renderItem={({ item, index }: any) => {
          return (
            <GalleryThumbnail
              imageUrl={item.url}
              isSelected={index === currentIndex}
              changeIndex={() => {
                setIndex(index);
              }}
            />
          );
        }}
        keyExtractor={(_item, index) => `${index}`}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};
