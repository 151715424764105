import React from "react";

import { DarkTheme } from "../Theme/DarkTheme";
import { LightTheme } from "../Theme/LightTheme";
import { AsyncStorage } from "../Tools/Storage";

export const usePaperTheme = () => {
  const [theme, setTheme] = React.useState<any>(LightTheme);

  React.useEffect(() => {
    const restorePrefs = async () => {
      try {
        const prefString = await AsyncStorage.getItem("theme");
        const preferences = JSON.parse(prefString || "");

        if (preferences) {
          setTheme(preferences.theme === "dark" ? DarkTheme : LightTheme);
        }
      } catch (e) {
        // ignore error
      }
    };

    restorePrefs();
  }, []);

  React.useEffect(() => {
    const savePrefs = async () => {
      try {
        await AsyncStorage.setItem(
          "theme",
          JSON.stringify({
            theme: theme === DarkTheme ? "dark" : "light",
          }),
        );
      } catch (e) {
        // ignore error
      }
    };

    savePrefs();
  }, [theme]);

  const togglePreferences = React.useMemo(
    () => ({
      toggleTheme: () => {
        return setTheme((theme: { dark: any; }) =>
          theme.dark ? LightTheme : DarkTheme,
        );
      },
      theme: theme,
    }),
    [theme],
  );

  return { theme, togglePreferences };
};
