import { useSelector } from "react-redux";

import { QueryHookOptions, useMutation } from "@apollo/client";

import { IRootReducer } from "../reducers/allReducers";
import { deleteAllItemsMutation, itemsQuery, markDeletedItemMutation } from "./Queries";

const useDeleteItems = () => {
  const uid = useSelector<IRootReducer, string>(
    (state) => state.appState.user.uid,
  );
  const resultsNumber = parseInt(
    useSelector<IRootReducer, string>((state) => state.appState.resultsNumber),
  );

  const [deleteItemHook] = useMutation(markDeletedItemMutation);
  const deleteItem = (itemID: string) => {
    //const variables = { data: { uid_itemID: uid + "_" + itemID } };
    const variables = { uid_itemID: uid + "_" + itemID };

    deleteItemHook({
      variables,
      // update: (store, { data: items }) => {
      //   const data: any = store.readQuery({
      //     query: itemsQuery,
      //     variables: {
      //       pageIndex: 1,
      //       uid,
      //       first: resultsNumber,
      //     },
      //   });

      //   const edges = data.userItems2.edges.filter((t: any) => {
      //     console.log(t);
      //     return items.deleteOneuser_item.uid_itemID.indexOf(t.node.id) < 0;
      //   });
      //   const userItemsNew = { ...data.userItems2, edges };
      //   store.writeQuery({
      //     query: itemsQuery,
      //     variables: {
      //       pageIndex: 1,
      //       uid,
      //       first: resultsNumber,
      //     },
      //     data: {
      //       userItems2: userItemsNew,
      //     },
      //   });
      // },
      // optimisticResponse: {
      //   __typename: "Mutation",
      //   deleteOneuser_item: {
      //     uid_itemID: uid + "_" + itemID,
      //     __typename: "user_item",
      //   },
      // },

      optimisticResponse: {
        __typename: "Mutation",
        updateOneuser_item: {
          id: itemID,
          deleted: true,
          __typename: "user_item",
        },
      },
    }).catch((e: any) => {
      console.log("deleteRow -> e", e);
    });
  };

  const [deleteAllItemsHook] = useMutation(deleteAllItemsMutation);
  const deleteAllItems = () => {
    const itemQueryVars: QueryHookOptions = {
      variables: { uid, first: resultsNumber, }
    };
    const variables = { data: { uid: { equals: uid } } };

    deleteAllItemsHook({
      variables,
      update: (store) => {
        store.writeQuery({
          query: itemsQuery,
          ...itemQueryVars,
          data: {
            userItems2: {
              __typename: "QueryUserItems2_Connection",
              edges: [],
              pageInfo: {
                __typename: "PageInfo",
                hasNextPage: false,
                hasPreviousPage: false,
                startCursor: null,
                endCursor: null,
              },
              totalCount: 0,
            },
          },
        });
      },

      refetchQueries: [
        {
          query: itemsQuery,
          ...itemQueryVars,
          fetchPolicy: "network-only"
        },
      ],

      optimisticResponse: {
        __typename: "Mutation",
        deleteManyuser_item: {
          count: 0,
          __typename: "BatchPayload",
        },
      },
    }).catch((e: any) => {
      console.log("Delete all items -> ", e);
    });
  };

  return { deleteItem, deleteAllItems };
};

export { useDeleteItems };
