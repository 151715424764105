import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

export const ImageCounter = ({
  currentIndex,
  allSize,
}: {
  currentIndex?: number;
  allSize?: number;
}) => {
  return (
    <View
      style={{
        position: "absolute",
        right: 15,
        bottom: 15,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {allSize && allSize > 1 && (
        <Text style={{ color: "white" }}>{currentIndex + "/" + allSize}</Text>
      )}
    </View>
  );
};
