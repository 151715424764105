import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { firebaseApp, messagingIsSupported } from "../firebase/firebase_config";

const registerMessaging = async (firebaseApp: any) => {
  try {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
      const token = await firebaseApp.messaging().getToken({
        vapidKey:
          "BMQu8U-z3-wpOamjxx-MqWu7dmOemafayY5dRIyklTgnVN5_h0O2OLvVixbfKwWTjvQdPH2MuKzbH-SaC6EAAHg",
        serviceWorkerRegistration: registration,
      });
      return token;
    }
  } catch (e) {
    console.log("Messaging: ", e);
  }
  return "";
};

export const useNotificationPermissions = () => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [permissionError, setPermissionError] = useState("");
  const dispatch = useDispatch();
  const safariPushID = "web.com.ubuyfirst";
  const blockedMessage =
    "You have blocked notifications. Please, set 'Notifications - Allow' in website settings.";

  useEffect(() => {
    checkPermissionState();
  }, [permissionGranted]);

  const checkPermissionState = async () => {
    const permission = getBrowserPushPermission();
    switch (permission) {
      case "granted":
        setPermissionGranted(true);
        if ("safari" in window) {
          //@ts-ignore
          const pushToken = window.safari.pushNotification.permission(
            safariPushID,
          ).deviceToken;
          savePushToken(pushToken);
        } else {
          const pushToken = await tryGetFirebasePushToken();
          savePushToken(pushToken);
        }
        break;
      case "denied":
        setPermissionGranted(false);
        setPermissionError(blockedMessage);
        dispatch({ type: "CLEAR_PUSH_TOKEN" });
        break;
      default:
        setPermissionGranted(false);
        dispatch({ type: "CLEAR_PUSH_TOKEN" });
        break;
    }
  };

  function getBrowserPushPermission() {
    let pushPermission = "";
    try {
      if ("safari" in window) {
        //@ts-ignore
        var permissionData = window.safari.pushNotification.permission(
          safariPushID,
        );
        pushPermission = permissionData.permission;
      } else {
        pushPermission = Notification.permission;
      }
    } catch (e) { }
    return pushPermission;
  }

  const onPermissionError = (error: string) => {
    switch (error) {
      case "messaging/notifications-blocked":
      case "messaging/permission-blocked":
        setPermissionError(blockedMessage);
    }
    setPermissionGranted(false);
    dispatch({ type: "CLEAR_PUSH_TOKEN" });
  };

  const tryGetFirebasePushToken = async () => {
    try {
      if (messagingIsSupported) {
        const currentToken = await registerMessaging(firebaseApp);
        if (currentToken) {
          setPermissionGranted(true);

          return currentToken;
        }
      } else {
        console.log("Browser does not support firebase push messaging.");
      }
    } catch (err) {
      //@ts-ignore
      onPermissionError(err.code);
    }
    return "";
  };
  const savePushToken = (pushToken: string) => {
    if (pushToken) {
      dispatch({ type: "SET_PUSH_TOKEN", payload: pushToken });
    } else {
      dispatch({ type: "CLEAR_PUSH_TOKEN" });
    }
  };

  const requestNotificationPermission = async () => {
    try {
      //@ts-ignore
      if ("safari" in window && "pushNotification" in window.safari) {
        //@ts-ignore
        getSafariPermissionState(safariPushID);
      } else {
        try {
          setPermissionError("");
          await Notification.requestPermission();
          checkPermissionState();
        } catch (err) {
          //@ts-ignore
          onPermissionError(err.code);
        }
      }
    } catch (err) {
      console.log("requestNotificationPermission -> err", err);
    }
  };

  const checkSafariPermissionState = (permissionData: any) => {
    switch (permissionData.permission) {
      case "denied":
        setPermissionGranted(false);
        setPermissionError(blockedMessage);
        dispatch({ type: "CLEAR_PUSH_TOKEN" });
        break;
      case "granted":
        if (permissionData.deviceToken) {
          setPermissionGranted(true);
          dispatch({
            type: "SET_PUSH_TOKEN",
            payload: permissionData.deviceToken,
          });
        }
        break;
    }
  };

  const getSafariPermissionState = (safariPushID: string) => {
    const webServiceUrl =
      window.location.protocol + "//" + window.location.host;
    //@ts-ignore
    window.safari.pushNotification.requestPermission(
      webServiceUrl,
      safariPushID,
      {},
      checkSafariPermissionState,
    );
  };

  return { permissionGranted, permissionError, requestNotificationPermission };
};
