import React from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ProgressBar, useTheme } from "react-native-paper";

import { ScreenHeader } from "../Auth/ScreenHeader";
import { useTerms } from "../Graphql/useTerms";
import { SearchQuery } from "./SearchQuery";

const QueryListComponent = ({ navigation }: any) => {
  const theme = useTheme();

  const { getTerms } = useTerms();
  const terms = getTerms("cache-and-network");

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        alignItems: "stretch",
        flexGrow: 1,
      }}
    >
      <ScreenHeader
        action="back"
        icon="close"
        title="eBay Searches"
        navigation={navigation}
      />
      <View style={{ flex: 1 }}>
        {terms ? (
          <FlatList
            style={{ marginTop: 8 }}
            data={terms}
            renderItem={(q: any) => (
              <SearchQuery searchQuery={q.item} navigation={navigation} />
            )}
          />
        ) : (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressBar
              indeterminate
              color={theme.dark ? "black" : theme.colors.primary}
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default QueryListComponent;
