import Constants from "expo-constants";
import * as IntentLauncher from "expo-intent-launcher";
import { useCallback } from "react";
import { Alert, Linking } from "react-native";

import { Platform } from "../Platform/index";
import { notificationChannelID } from "./NotificationService";

export const openNotificationSettings = () => {
  let action = "";
  let extras: any = [];
  switch (Platform.OS) {
    case "ios":
      action = "app-settings:";
      break;
    case "android":
      action = "android.settings.APP_NOTIFICATION_SETTINGS";
      extras = [
        { "android.provider.extra.APP_PACKAGE": "com.ubuyfirst" },
        {
          "android.provider.extra.CHANNEL_ID": notificationChannelID,
        },
      ];
      break;
  }

  const openNotificationSettingsCallback = useCallback(async () => {
    try {
      if (Platform.OS === "ios") {
        await Linking.openURL(action);
      } else {
        IntentLauncher.startActivityAsync(
          IntentLauncher.ActivityAction.APP_NOTIFICATION_SETTINGS, {
          extra:
          {
            "android.provider.extra.APP_PACKAGE":
              Constants.expoConfig?.android?.package
          }
        }
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        Alert.alert(e.message);
      }
    }
  }, [action, extras]);

  return openNotificationSettingsCallback;
};
