import React from "react";
import { Image, TouchableOpacity } from "react-native";

export const GalleryThumbnail = ({
  imageUrl,
  isSelected,
  changeIndex,
}: any) => {
  return (
    <TouchableOpacity
      style={{
        height: 80,
        width: 80,
        borderRadius: 5,
        marginHorizontal: 1,
        backgroundColor: "rgba(0,0,0,0.1)",
        justifyContent: "center",
        alignItems: "center",
        borderBottomWidth: isSelected ? 2 : 0,
        borderColor: isSelected ? "#00ffff" : "",
      }}
      onPress={changeIndex}
      //@ts-ignore
      onMouseEnter={changeIndex}
    >
      <Image
        source={{ uri: imageUrl }}
        style={{
          height: isSelected ? 78 : 80,
          width: isSelected ? 78 : 80,
          borderRadius: 4,
        }}
        resizeMode="cover"
      />
    </TouchableOpacity>
  );
};
