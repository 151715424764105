import React, { createContext } from "react";
import { Provider as PaperProvider } from "react-native-paper";

import { usePaperTheme } from "../hooks/usePaperTheme";

export const ThemeToggleContext: any = createContext({});

export function ThemeProvider(props: any) {
  const { theme, togglePreferences } = usePaperTheme();

  return (
    <PaperProvider theme={theme}>
      <ThemeToggleContext.Provider value={togglePreferences}>
        {props.children}
      </ThemeToggleContext.Provider>
    </PaperProvider>
  );
}
