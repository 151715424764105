import React from "react";
import { StyleSheet, View } from "react-native";
import { Divider, Subheading, Switch, TouchableRipple } from "react-native-paper";

import { useBrightness } from "../hooks/useBrightness";
import { BrightnessSlider } from "./BrightnessSlider";

export const BrightnessComponent = () => {
  const {
    brightnessEnabled,
    appBrightnessLevel,
    toggleBrightnessEnabled,
    applyBrightnessOnSliderChange,
  } = useBrightness();

  return (
    <View>
      <TouchableRipple onPress={toggleBrightnessEnabled}>
        <View style={styles.preference}>
          <Subheading>Custom brightness</Subheading>
          <View pointerEvents="none">
            <Switch value={brightnessEnabled} />
          </View>
        </View>
      </TouchableRipple>
      {brightnessEnabled && (
        <>
          <Divider />
          <View style={styles.preference}>
            <Subheading>Brightness level</Subheading>
            <BrightnessSlider
              value={appBrightnessLevel}
              onSlidingComplete={applyBrightnessOnSliderChange}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 12,
    alignItems: "center",
  },
});
