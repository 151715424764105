import { activateKeepAwakeAsync, deactivateKeepAwake, isAvailableAsync } from "expo-keep-awake";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { IRootReducer } from "../reducers/allReducers";

export const useAppKeepAwake = () => {
  const screenTimeout = useSelector<IRootReducer, number>(state => state.appState.screenTimeout);

  useEffect(() => {
    const checkAndActivateKeepAwake = async () => {
      const keepAwakeAvailable = await isAvailableAsync();
      if (keepAwakeAvailable) {
        if (screenTimeout) {
          await activateKeepAwakeAsync();
        } else {
          deactivateKeepAwake();
        }
      }
    };

    checkAndActivateKeepAwake();

    // Cleanup function
    return () => {
      deactivateKeepAwake();
    };
  }, [screenTimeout]);
};
