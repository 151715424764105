import React, { useState } from "react";
import { View } from "react-native";
import { IconButton, Menu } from "react-native-paper";

import { NavigationProp, useNavigation } from "@react-navigation/native";

import MenuWithTimeout from "../components/MenuWithTimeout";

export const QueryListMenu = (props: any) => {
  const navigation = useNavigation<NavigationProp<any>>();
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <View>
      <MenuWithTimeout
        visible={menuVisible}
        onDismiss={() => setMenuVisible(false)}
        anchor={
          <IconButton
            icon="dots-vertical"
            size={21}
            onPress={() => setMenuVisible(!menuVisible)}
            iconColor={"white"}
          />
        }
      >
        <Menu.Item
          title="Import/Export"
          leadingIcon="application-import"
          onPress={() => {
            setMenuVisible(false);
            navigation.navigate("QueryBackup");
          }}
        />
      </MenuWithTimeout>
    </View>
  );
};
