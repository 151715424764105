import "firebase/compat/database";
import "firebase/compat/messaging";
import "firebase/compat/auth";

import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyA92simGlj43bVvgfYE6A4IE7Jiah3E_4Y",
  authDomain: "ubuyfirst-rn.firebaseapp.com",
  databaseURL: "https://ubuyfirst-rn.firebaseio.com",
  projectId: "ubuyfirst-rn",
  storageBucket: "ubuyfirst-rn.appspot.com",
  messagingSenderId: "734829675954",
  appId: "1:734829675954:web:a6abdae631d0dc6d6410ab",
};

const firebaseApp = firebase.initializeApp(firebaseConfig, "firebaseApp");
const messaging = () => firebaseApp.messaging();
const ServerValue = firebase.database.ServerValue;
const auth = () => firebaseApp.auth();
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const messagingIsSupported = firebase.messaging.isSupported();

export {
  firebaseApp,
  auth,
  messaging,
  messagingIsSupported,
  EmailAuthProvider,
  ServerValue,
};
