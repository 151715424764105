import React from "react";

import Slider from "@react-native-community/slider";

import { useCardsWidth } from "../hooks/useCardsWidth";

export const CardWidthSlider = () => {
  const { cardWidth, setCardWidth, window } = useCardsWidth();
  return (
    <Slider
      style={{ flex: 1, marginLeft: 50 }}
      minimumValue={150}
      maximumValue={window.width / 1.8}
      maximumTrackTintColor="#000000"
      onSlidingComplete={setCardWidth}
      value={cardWidth}
      //onResponderGrant={() => true}
      //tapToSeek={true}
      //hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    />
  );
};
