import React from "react";
import { IconButton } from "react-native-paper";

export const DeleteItemButton = (props: {
  deleteItem: (arg0: any) => void;
  itemID: any;
}) => (
  <IconButton
    style={{
      opacity: 0.2,
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 9,
    }}
    onPress={() => {
      props.deleteItem(props.itemID);
    }}
    icon="close"
    size={20}
  />
);
