import React, { useRef, useState } from "react";

import MenuWithTimeout from "../MenuWithTimeout";
import { DropdownItems } from "./DropdownItems";
import { DropdownTextInput } from "./DropdownTextInput";

const CheckedDropdown = ({
  checkedItems,
  label,
  onChange,
}: {
  label: string;
  onChange: (arg0: any) => void;
  checkedItems: string;
}) => {
  const checkedItemsRef = useRef(checkedItems);
  const [menuVisible, setMenuVisible] = useState(false);
  const [canClose, setCanClose] = useState(false);

  const toggleMenuVisibility = () => {
    if (!menuVisible) {
      setCanClose(false);
      setTimeout(() => setCanClose(true), 1000);
    }
    setMenuVisible(!menuVisible);
  };

  const onMenuDismiss = () => {
    if (canClose) {
      setMenuVisible(false);
      onChange(checkedItemsRef.current);
    }
  };

  return (
    <MenuWithTimeout
      visible={menuVisible}
      onDismiss={onMenuDismiss}
      anchor={
        <DropdownTextInput
          label={label}
          onPress={toggleMenuVisibility}
          text={"Any"}
          selectedItemsRef={checkedItemsRef}
        />
      }
    >
      {menuVisible && <DropdownItems checkedItemsRef={checkedItemsRef} />}
    </MenuWithTimeout>
  );
};

export { CheckedDropdown };
